<h2 class="text-center mb-3 mt-3">{{ 'PAGANTE.TITLE' | translate }}</h2>

<ng-wizard [config]="config" (stepChanged)="stepChanged($event)">
    <ng-wizard-step title="{{ 'TABS.PAGAMENTO' | translate}}" description="{{ 'TABS.PAGANTE' | translate}}">
        <app-anagrafica *ngIf="index === 0" (servizioAna)="onServizioDaAna($event)"></app-anagrafica>
    </ng-wizard-step>
    <!--
  <ng-wizard-step [title]="tabs[0].idtab" [description]="tabs[0].descrizione">
    <app-anagrafica *ngIf="index === 0"></app-anagrafica>
  </ng-wizard-step>
  -->
    <!-- <ng-wizard-step [title]="tabs[1].idtab" [description]="tabs[1].descrizione">
    <app-anagrafica2 *ngIf="index === 1"></app-anagrafica2>
  </ng-wizard-step> -->

    <ng-wizard-step title="{{ 'TABS.PAGAMENTO' | translate}}" description="{{ 'TABS.RIEPILOGO_DATI' | translate}}">
        <app-pagamento *ngIf="index === 1" [tipoServizio]="this.tipoS"></app-pagamento>
    </ng-wizard-step>

    <ng-wizard-step title="{{ 'TABS.ESITO' | translate}}" description="{{ 'TABS.ESITO_TRANSAZIONE' | translate}}">
        <app-esito *ngIf="index === 2" [idCarrelloSorgente]="idCarrelloSorgente" [listIdPagamentoTas]="listIdPagamentoTas" [statoPagamento]="statoPagamento"></app-esito>
    </ng-wizard-step>
</ng-wizard>
