import { Injectable } from '@angular/core';
import { Anagrafica } from '../models/anagrafica';
import FakeAnagrafica from '../../../assets/fake-anagafica.json';

export abstract class FormDataStorage<TType>{
  constructor(
    protected type: (new () => TType)) { }

  protected Data: TType;
  // private Data_OLD: TType;

  get data(): TType { return this.Data; }
  // get dataOld(): TType { return this.Data_OLD; }

  load(that: TType): void {
    this.Data = that;
  }

  // setdataOld(data: TType) {
  //   this.Data_OLD = data;
  // }

  reset(): void {
    //this.Data_OLD = this.Data;
    this.Data = new this.type();
  }
}

@Injectable({
  providedIn: 'root'
})
export class FormDataAnagraficaStorage extends FormDataStorage<Anagrafica> {

  constructor() {
    super(Anagrafica);

    this.Data = new Anagrafica();
    //this.Data = Object.assign( new Anagrafica(), FakeAnagrafica as Anagrafica);
  }
}


