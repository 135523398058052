<div class="form-row">

  <div class="col-sm-12">
    <div class="card mt-4 mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h4>{{ 'ESITO_PAGAMENTO.TITLE' | translate }}</h4>
            
            <h4>{{ 'ESITO_PAGAMENTO.BENVENUTO' | translate }}</h4>
            
            <h1>{{ testDenominazioneEnte | translate}}</h1>
            <br />
            <p>{{ 'ESITO_PAGAMENTO.RECAPTCHA' | translate }}</p>
          </div>
        </div>
        <div class="row mt-5 pt-5">
          <div class="col-sm-4 align-middle d-flex justify-content-center">
            <img class="img-fluid" src='..\..\assets\img\imgsx.png' alt="Camera">
          </div>
          <div class="col-sm-4 mt-5 pt-5 mb-5 pb-5 d-flex justify-content-center">
            <span  class="mt-5 mb-5">
             
              <form [formGroup]="aFormGroup">
                <ngx-recaptcha2 #captchaElem [siteKey]="siteKey"
                 (success)="handleSuccess($event)" 
                 [hl]="lingua" formControlName="recaptcha">
                </ngx-recaptcha2>
              </form>

           </span>
          </div>
          <div class="col-sm-4 align-text-bottom d-flex justify-content-center">
             <!--<img class="img-fluid" src='..\..\assets\img\imgdx.png' (click)="vai()" alt="Impresa">  -->
            <img class="img-fluid" src='..\..\assets\img\imgdx.png' alt="Impresa"> 
          </div>
        </div>
      </div>
    </div>
  </div>

</div>