<form class="mt-4 mb-4" #pagamentoForm="ngForm">
    <app-info-pagamento *ngIf="causaleServizio" [form]="pagamentoForm" [anagrafica]="anagrafica" [nameModelGroup]="'pagamento'" [isDetail]="true" (inviaSePlus)="metodoSePlus($event)">
    </app-info-pagamento>

    <!--
    <div [ngClass]="{'disabled':true}">
        <app-dettagli [invioSelezionati]="arrSelezionati" [form]="pagamentoForm" [anagrafica]="anagrafica" [nameModelGroup]="'pagamento'" (inviaSePlus)="metodoSePlus($event)">

        </app-dettagli>
    </div>
-->

    <div [ngClass]="{ disabled: true }">
        <app-info-pagamento-plus [invioSelezionati]="arrSelezionati" [form]="pagamentoForm" [anagrafica]="anagrafica" [nameModelGroup]="'pagamento'" (inviaSePlus)="metodoSePlus($event)" [nascondiRighe]="clearTabella" (nascondiRigheReturn)="leggeReturnRighe($event)">
        </app-info-pagamento-plus>
    </div>
    <app-info-importo *ngIf="visualizzaImporto" [form]="pagamentoForm" [anagrafica]="anagrafica" [nameModelGroup]="'pagamento'" [isDetail]="true" [seSipaPlus]="seSipa"></app-info-importo>

    <app-anagrafica-persona class="mb-4" [form]="pagamentoForm" [persona]="anagrafica.pagante" [nameModelGroup]="'pagante'" suffixTitle="{{ 'ANAGRAFICA.DEL_PAGANTE' | translate }}" [isDetail]="true"></app-anagrafica-persona>

    <app-anagrafica-persona *ngIf="paganteNonCoincide" [form]="pagamentoForm" [persona]="anagrafica.cliente" [nameModelGroup]="'cliente'" suffixTitle="{{ 'ANAGRAFICA.DEL_CLIENTE' | translate }}" [isDetail]="true">
    </app-anagrafica-persona>

    <app-info-codice-destinatario [form]="pagamentoForm" [anagrafica]="anagrafica" [nameModelGroup]="'pagamento'" [isDetail]="true"></app-info-codice-destinatario>
</form>

<hr />
<div class="form-group col-sm-12 mt-4">
    <div class="text-center">
        <button type="button" class="btn btn-info" (click)="showPreviousStep()">
      {{ "BUTTON.INDIETRO" | translate }}
    </button>
        <button type="button" class="btn btn-success ml-2" (click)="toggleBlocking('Attendere, prego...'); confermaPagamento()">
      {{ "BUTTON.CONFERMA_PAGAMENTO" | translate }}
    </button>
    </div>
</div>