<!--
<div class='header' [ngStyle]="{'background-color': style?.headerColorBg}">
  <div class="row align-items-center">
    <div class="col-12 col-lg-2 col-md-4 col-sm-5" style="border: 1px; border-color: aqua; border-style: solid;">
        <img *ngIf="nomeImg" [src]="'assets/img/'+nomeImg" height="70px">
    </div>
    <div class="col-12 col-lg-9 col-md-7 col-sm-6">
      <h5 class="label font-weight-bold float-left" [ngStyle]="{'color': style?.headerColorFg}">
        {{style?.denominazioneEnte}}
      </h5>
    </div>
    <div class="col-12 col-lg-1 col-md-1 col-sm-1 float-right">
      <button type="button" class="btn btn-default float-right" [ngStyle]="{'color': style?.headerColorFg}"
        (click)="openModal('custom-modal-2')">?</button>
    </div>
  </div>
</div> -->


<div class='header' [ngStyle]="{'background-color': style?.headerColorBg}">
  <div class="row align-items-center">
    <div class="col-12 col-lg-3 col-md-3 col-sm-3">
      
        <img *ngIf="nomeImg" #logo [src]="'assets/img/'+nomeImg" height="70px" style="max-width: 90%;"/>
        
      
    </div>

    <div class="col-12 col-lg-7 col-md-7 col-sm-7">
      <h4 class="label font-weight-bold" [ngStyle]="{'color': style?.headerColorFg}">
        {{ nameDenominazioneEnte }}
      </h4>
    </div>

    <div *ngIf="flagInt=='true'" class="col-12 col-lg-1 col-md-1 col-sm-1 float-right scelta_lingua">
      <select #langSelect (change)="onChangeLanguage(langSelect.value)" [disabled]="disableLang">
        <option *ngFor="let lang of translate.langs"
        [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
      </select>
      <img src="assets/img/{{translate.currentLang}}.png" style="padding-left: 12%; max-width: 80%;
      height: auto;"/>
    </div>

    <div class="col-12 col-lg-1 col-md-1 col-sm-1 float-right">
      <button type="button" class="btn btn-default float-right" [ngStyle]="{'color': style?.headerColorFg}"
        (click)="openModal('custom-modal-2')">?</button>
    </div>
  </div>
</div>

<jw-modal id="custom-modal-2">
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-11">
          <h3>{{ 'MODALE.INFORMAZIONI' | translate }}</h3>
        </div>
        <div class="col-1">
          <button class="btn btn-flat float-right" (click)="closeModal('custom-modal-2');">X</button>
        </div>
      </div>
    </div>
    <div class="card-body" style="height:600px">

      <h4 class="card-title my-3">{{ 'MODALE.TITLE' | translate }}</h4>

      <!-- <h6 class="card-subtitle text-muted my-3">sotto titolo</h6> -->
      <p [innerHTML]="'MODALE.PARTE_1' | translate"></p>
      <p [innerHTML]="'MODALE.PARTE_2' | translate"></p>
      <p [innerHTML]="'MODALE.PARTE_3' | translate"></p>
      <p [innerHTML]="'MODALE.PARTE_4' | translate"></p>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <button class="btn btn-flat" (click)="closeModal('custom-modal-2');">{{ 'BUTTON.CHIUDI' | translate }}</button>
    </div>
  </div>
</jw-modal>
