
import { Component, OnInit, AfterContentChecked, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormComponent } from 'src/app/_shared/form-component';

@Component({
  selector: 'app-header-esito',
  templateUrl: './header-esito.component.html',
  styleUrls: ['./header-esito.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],

})
export class HeaderEsitoComponent extends FormComponent implements OnInit, AfterContentChecked {

  @Input() enteDescrizione: string;
  @Input() applServizioDescrizione: string;
  @Input() causale: string;


  constructor(public translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    if (!this.translate.currentLang) {
      this.translate.resetLang(this.translate.currentLang);
      this.translate.reloadLang(this.translate.currentLang);
    }
    this.disableControls();

  }

}
