import {
  Component,
  OnInit,
  Input,
  AfterContentChecked,
  OnDestroy,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Anagrafica } from 'src/app/_shared/models/anagrafica';
import { EntiService } from 'src/app/_shared/services/enti.service';
import {
  Ente,
  Applicazione,
  CausaleDto,
} from 'src/app/_shared/models/ente';
import {
  ControlContainer,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { FormComponent } from 'src/app/_shared/form-component';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitterService } from 'src/app/_shared/event-emitter-service';
import { AuthStorageService } from 'src/app/_shared/auth-storage.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Utility } from 'src/app/_shared/utility';
import { DettagliService } from 'src/app/_shared/services/dettagli.service';
import { SipaPlusStorageService } from 'src/app/_shared/sipaplus-storage.service';
//import { debug } from "console";

@Component({
  selector: 'app-info-pagamento',
  templateUrl: './info-pagamento.component.html',
  styleUrls: ['./info-pagamento.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  providers: [DettagliService],
})
export class InfoPagamentoComponent
  extends FormComponent
  implements OnInit, AfterContentChecked
{
  @Input() anagrafica: Anagrafica;
  @Output() servizio = new EventEmitter();
  @Output() sePlus = new EventEmitter();
  @Output() controlloCinqueServizi = new EventEmitter();
  @Output() inviaSePlus = new EventEmitter();

  mostraCausaleAttuale: boolean;
  mostraCausaRaggruppamento: boolean;

  local: any;
  hasEntiSubscription: Subscription;
  test: string;

  visualizzaGrigliaCausali: boolean;
  plus: string;
  formAffitti: FormGroup;
  //----------------------

  constructor(
    private entiSrv: EntiService,
    private translate: TranslateService,
    private eventEmitterService: EventEmitterService,
    private storage: AuthStorageService,
    private formBuilder: FormBuilder,
    private dataService: DettagliService,
    private sipaPlusStorageService: SipaPlusStorageService
  ) {
    super();
    this.formAffitti = this.formBuilder.group({
      listaDett: this.formBuilder.array([], [Validators.required]),
    });
  }

  get entiEnabled(): Ente[] {
    return this.entiSrv.entiInMemory;
  }

  get applicazioniEnabled(): Applicazione[] {
    const enteSelected = this.entiEnabled?.find(
      (x) => x.codiceEnte === this.anagrafica.codiceEnte
    );
    return enteSelected?.elencoApplicazioni ?? [];
  }

  get isUserST(): boolean {
    return this.entiSrv.isUserST;
  }

  ngOnInit(): void {
    this.visualizzazioniSeSipaPlus();
    if (!this.anagrafica.quantitaItem) {
      this.anagrafica.quantitaItem = 1;
    }
  }

  ngAfterContentChecked(): void {
    if (this.isDetail) {
      this.disableControls();
    } else if (!this.isUserST) {
      this.disableControl('ente');
    }
  }

  visualizzazioniSeSipaPlus() {
    if (!this.isDetail) {
      this.hasEntiSubscription = this.entiSrv.hasEnti$.subscribe((status) => {
        if (status) {
          this.plus = this.sipaPlusStorageService.getSipaPlus();

          this.sePlus.emit(this.plus);

          if (this.plus === '0') {
            this.visualizzaGrigliaCausali = false;
            this.inviaSePlus.emit('0');
          } else if (this.plus === '1') {
            this.visualizzaGrigliaCausali = true;
            this.inviaSePlus.emit('1');
          }
        }
      });
    }
  }

  onSelectApplicazione(value): void {
    this.anagrafica.idServizio = value;
    const applicazioneSelezionata = this.applicazioniEnabled.find(app => app.idApplicazione == this.anagrafica.idServizio);
    this.anagrafica.cdp = applicazioneSelezionata?.cdp;
    this.anagrafica.cpk = applicazioneSelezionata?.cpk;
    this.anagrafica.tipoContabilizzazione = applicazioneSelezionata?.tipoContabilizzazione;
  }
}
