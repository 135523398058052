<div class="form-row">
  <!-- <div class="col-sm-2" style="border: 1px solid black" id="NoMobile1">
    <img class="img-fluid" src='..\..\assets\img\imgsx.png' alt="Camera">
  </div> -->
  <div class="col-sm-12">
    <div class="card mt-4 mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h4>{{ "AUTENTICAZIONE.TITLE" | translate }}</h4>

            <h4>{{ "AUTENTICAZIONE.BENVENUTO" | translate }}</h4>
            
            <h1>{{ testDenominazioneEnte | translate}}</h1>
            <br />
            <p>{{ "AUTENTICAZIONE.RECAPTCHA" | translate }}</p>
          </div>
        </div>
        <div class="row mt-5 pt-5">
          <div class="col-sm-4 align-middle d-flex justify-content-center">
            <img
              class="img-fluid"
              src="..\..\assets\img\imgsx.png"
              alt="Camera"
            />
          </div>
          <div
            class="col-sm-4 mt-5 pt-5 mb-5 pb-5 d-flex justify-content-center"
          >
            <span class="mt-5 mb-5">
              <form [formGroup]="aFormGroup">
                <ngx-recaptcha2
                  #captchaElem
                  [siteKey]="siteKey"
                  (success)="handleSuccess($event)"
                  [hl]="lingua"
                  formControlName="recaptcha"
                >
                </ngx-recaptcha2>
              </form>

              <!--
                <re-captcha (resolved)="resolved($event)" siteKey="6LfMguMZAAAAAHZCXErVcdnck6I0hHZbGt4tOoen"></re-captcha>
                -->
              <!--IC (new)
              <re-captcha (resolved)="resolved($event)" siteKey="6LdMd2caAAAAAIYtJsVv8g-zxzPmPngBScC-V-0c"></re-captcha>-->
              <!--LacriolaBaldin
              <re-captcha (resolved)="resolved($event)" siteKey="6Lf5RzcaAAAAAMF0Bn0yUoneO4XOOnww3YJlf1oL"></re-captcha>-->
            </span>
          </div>
          <div class="col-sm-4 align-text-bottom d-flex justify-content-center">
            <!--<img class="img-fluid" src='..\..\assets\img\imgdx.png' (click)="test()" alt="Impresa">  -->
            <img
              class="img-fluid"
              src="..\..\assets\img\imgdx.png"
              alt="Impresa"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="col-sm-2" id="NoMobile2" style="border: 1px solid black">
    <img class="img-fluid" src='..\..\assets\img\imgdx.png' alt="Impresa">
  </div> -->
</div>
