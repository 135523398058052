import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeAutenticazioneComponentFunction = new EventEmitter();
  invokeCambioServizioFunction = new EventEmitter();

  subsVar: Subscription;
  subsVarServizio: Subscription;

  constructor() { }

  onChangeLanguage(lang: String) {
    //console.log("EventEmitterService");
    this.invokeAutenticazioneComponentFunction.emit(lang);
  }

  // onChangeServizio(servizio: String)
  // {
  //   this.invokeCambioServizioFunction.emit(servizio);
  // }

}
