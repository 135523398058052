import { stringify } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { Utility } from '../utility';

@Injectable({
  providedIn: 'root',
})
export class PaganteStorageService {
  private readonly KEY_CODICE_FISCALE: string = 'codiceFiscale';
  private readonly KEY_PARTITA_IVA: string = 'partitaIva';
  private readonly KEY_NOME: string = 'nome';
  private readonly KEY_COGNOME: string = 'cognome';
  private readonly KEY_CHECKESTERO: string = 'check';
  private readonly KEY_PROVINCIA: string = 'provincia';
  private readonly KEY_COMUNE: string = 'comune';
  private readonly KEY_INDIRIZZO: string = 'indirizzo';
  private readonly KEY_CIVICO: string = 'civico';
  private readonly KEY_CAP: string = 'cap';
  private readonly KEY_MAIL: string = 'email';
  private readonly KEY_SIGLA: string = 'siglaProv';
  private readonly KEY_ERROREPS: string = 'errorePS';
  private readonly KEY_APPLICAZIONE: string = 'applicazione';
  private readonly KEY_CHOISESBACK: string = 'choisesBack';
  private readonly KEY_CONTROLLOTOTALE: string = 'controlloTotale';
  get storage(): Storage {
    return sessionStorage;
  }

  constructor() {}

  getTotale(): string {
    return this.storage.getItem(this.KEY_CONTROLLOTOTALE);
   }

   setTotale(controlloTotale): void {
     this.storage.setItem(this.KEY_CONTROLLOTOTALE, controlloTotale);
   }

  getChoisesBack() {
    var storedNames =JSON.parse(this.storage.getItem(this.KEY_CHOISESBACK))
    return storedNames ;
  }

  setChoisesBack(choisesBack): void {
    var dett = [];
    dett[0] = choisesBack;
    this.storage.setItem(this.KEY_CHOISESBACK,  JSON.stringify(dett));
  }

  getApplicazione(): string {
   return this.storage.getItem(this.KEY_APPLICAZIONE);
  }

  setApplicazione(applicazione): void {
    this.storage.setItem(this.KEY_APPLICAZIONE, applicazione);
  }

  getErrorePagamentoSpontaneo(): string {
    return this.storage.getItem(this.KEY_ERROREPS);
  }

  setErrorePagamentoSpontaneo(errPs: string): void {
    this.storage.setItem(this.KEY_ERROREPS, errPs);
  }
  getCodiceFiscale(): string {
    return this.storage.getItem(this.KEY_CODICE_FISCALE);
  }

  setCodiceFiscale(cod: string): void {
    this.storage.setItem(this.KEY_CODICE_FISCALE, cod);
  }

  getPartitaIva(): string {
    return this.storage.getItem(this.KEY_PARTITA_IVA);
  }

  setPartitaIva(cod: string): void {
    this.storage.setItem(this.KEY_PARTITA_IVA, cod);
  }

  setNome(nome: string) {
    if (!Utility.isStringNullOrUndefinedOrEmpty(nome))
      this.storage.setItem(this.KEY_NOME, nome);
    else this.storage.setItem(this.KEY_NOME, '');
  }

  getNome(): string {
    let temp = this.storage.getItem(this.KEY_NOME);
    if (!Utility.isStringNullOrUndefinedOrEmpty(temp))
      return this.storage.getItem(this.KEY_NOME);
    else return '';
  }

  getCognome(): string {
    return this.storage.getItem(this.KEY_COGNOME);
  }

  setCognome(cpgnome: string): void {
    this.storage.setItem(this.KEY_COGNOME, cpgnome);
  }

  getCheck(): string {
    return this.storage.getItem(this.KEY_CHECKESTERO);
  }

  setCheck(check: string): void {
    this.storage.setItem(this.KEY_CHECKESTERO, check);
  }

  getProvincia(): string {
    return this.storage.getItem(this.KEY_PROVINCIA);
  }

  setProvincia(p: string): void {
    this.storage.setItem(this.KEY_PROVINCIA, p);
  }

  setComune(com: string): void {
    this.storage.setItem(this.KEY_COMUNE, com);
  }

  getComune(): string {
    return this.storage.getItem(this.KEY_COMUNE);
  }

  setIndirizzo(ind: string): void {
    this.storage.setItem(this.KEY_INDIRIZZO, ind);
  }

  getIndirizzo(): string {
    return this.storage.getItem(this.KEY_INDIRIZZO);
  }

  setCivico(civ: string): void {
    this.storage.setItem(this.KEY_CIVICO, civ);
  }

  getCivico(): string {
    return this.storage.getItem(this.KEY_CIVICO);
  }

  setCap(cap: string): void {
    this.storage.setItem(this.KEY_CAP, cap);
  }

  getCap(): string {
    return this.storage.getItem(this.KEY_CAP);
  }

  setMail(mail: string): void {
    this.storage.setItem(this.KEY_MAIL, mail);
  }

  getMail(): string {
    return this.storage.getItem(this.KEY_MAIL);
  }

  getSiglaProv(): string {
    return this.storage.getItem(this.KEY_SIGLA);
  }

  setSiglaProv(cod: string): void {
    this.storage.setItem(this.KEY_SIGLA, cod);
  }

  clear() {
    this.storage.removeItem(this.KEY_CAP);
    this.storage.removeItem(this.KEY_CHECKESTERO);
    this.storage.removeItem(this.KEY_CIVICO);
    this.storage.removeItem(this.KEY_CODICE_FISCALE);
    this.storage.removeItem(this.KEY_PARTITA_IVA);
    this.storage.removeItem(this.KEY_COGNOME);
    this.storage.removeItem(this.KEY_COMUNE);
    this.storage.removeItem(this.KEY_INDIRIZZO);
    this.storage.removeItem(this.KEY_MAIL);
    this.storage.removeItem(this.KEY_NOME);
    this.storage.removeItem(this.KEY_PROVINCIA);
    this.storage.removeItem(this.KEY_SIGLA);
    this.storage.removeItem(this.KEY_ERROREPS);
    this.storage.removeItem(this.KEY_APPLICAZIONE);
    this.storage.removeItem(  this.KEY_CONTROLLOTOTALE);

    this.storage.removeItem('choicesSelezionati');
    this.storage.removeItem('choices');
    this.storage.removeItem('listId');
    this.storage.removeItem('oltreCinque');
  }
}
