import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagamentoResponse } from '../models/dtos/pagamento-response';
import { HttpService } from '../http.service';
import { Anagrafica } from '../models/anagrafica';
import { PagamentoRequest } from '../models/dtos/pagamento-request';
import { AuthStorageService } from '../auth-storage.service';
import { CarrelloResponse } from '../models/dtos/carrello-response';
import { TranslateService } from '@ngx-translate/core';
import { StatoPagamentoRequest } from '../models/dtos/statopagamento-request';
import { SipaEsito } from '../models/dtos/sipa-response';
import { SipaPlusStorageService } from '../sipaplus-storage.service';
// import CarrelloFakeResponse from '../../../assets/carrello-fake-response.json';

@Injectable({
  providedIn: 'root'
})
export class PagamentoService {

  constructor(
    private httpApi: HttpService,
    private storage: AuthStorageService,
    private traslate: TranslateService,
    private sipaPlusStorageService: SipaPlusStorageService
  ) { }

  pagaCarrello(vm: Anagrafica): Observable<PagamentoResponse>
  {
    const pagamento = PagamentoRequest.CreateFromVM(vm, this.traslate.currentLang, this.sipaPlusStorageService.getSipaPlus());
    pagamento.sipaCodiceEnte = this.storage.getCodiceEnte();
    pagamento.sipaGuid = this.storage.getGuid();
    pagamento.sipaToken = this.storage.getToken();
    return this.httpApi.pagaCarrello(pagamento);
  }

  leggiCarrello(token: string): Observable<CarrelloResponse> {
    return this.httpApi.leggiCarrello(token)
      .pipe(
        // map(response => {
        //   if (response.sipaEsito.codiceEsito === 'KO') {
        //     return CarrelloFakeResponse as CarrelloResponse;
        //   }else{
        //     return response;
        //   }
        // }),
        // catchError(err => {
        //cancellare usato per test
        //return of(CarrelloFakeResponse as CarrelloResponse);
        // })
      );
  }

  aggiornaStatoPagamento(statoPagamento:string, idCarrelloSorgente:string): Observable<SipaEsito> {
    const statoPagamentoRequest = StatoPagamentoRequest.CreateFromVM(statoPagamento, idCarrelloSorgente, this.sipaPlusStorageService.getSipaPlus());
    statoPagamentoRequest.sipaCodiceEnte = this.storage.getCodiceEnte();
    statoPagamentoRequest.sipaGuid = this.storage.getGuid();
    statoPagamentoRequest.sipaToken = this.storage.getToken();

    return this.httpApi.aggiornaStatoPagamento(statoPagamentoRequest);
  }


}
