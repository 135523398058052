import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AutenticazioneComponent } from './autenticazione/autenticazione.component';
import { PagamentoSpontaneoComponent } from './pagamento-spontaneo/pagamento-spontaneo.component';
import { PageErrorComponent } from './page-error/page-error.component';
import { EsitoPagamentoComponent } from './esito-pagamento/esito-pagamento.component';
import { NuovoPagamentoComponent } from './nuovoPagamento/nuovoPagamento.component';
import { CaptchaEsitoComponent } from './esito-pagamento/captcha-esito/captchaEsito.component';

const routes: Routes = [
  // canActivate: [AuthenticationGuard]
  { path: 'Pagamento', component: PagamentoSpontaneoComponent },
  { path: 'Pagamento/:idtab', component: PagamentoSpontaneoComponent },

  { path: 'EsitoPagamento/:token', component: CaptchaEsitoComponent },
  { path: 'EsitoPagamento/:lingua/:codiceEnte/:token', component: CaptchaEsitoComponent },
  { path: 'EsitoPagamentoCaptcha', component: EsitoPagamentoComponent },

  { path: 'Autenticazione', component: AutenticazioneComponent },
  { path: 'NuovoPagamento', component: NuovoPagamentoComponent },
  { path: 'Errore', component: PageErrorComponent },
  { path: '',  redirectTo: '/Pagamento', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  //imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
