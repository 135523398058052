import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './../_shared/authentication.service';
import { Router } from '@angular/router';
import { AuthStorageService } from '../_shared/auth-storage.service';
import { ConfigurazioneStyleService } from '../_shared/config/configurazione-style.service';
import { Utility } from '../_shared/utility';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventEmitterService } from '../_shared/event-emitter-service';
import { tap } from 'rxjs/operators';
import { ConfigService } from '../_shared/config.service';

@Component({
  selector: 'app-nuovoPagamento',
  templateUrl: './nuovoPagamento.component.html',
  styleUrls: ['./nuovoPagamento.component.css'],
})
export class NuovoPagamentoComponent implements OnInit, OnDestroy{
  lingua: string;
  siteKey: string;
  codiceEnte: string;

  aFormGroup2: FormGroup;



  constructor(private authsvr: AuthenticationService, private router: Router,
              private authStorage: AuthStorageService,
              private configStyle: ConfigurazioneStyleService,
              private translate :TranslateService,
              private eventMitterLang: EventEmitterService,
              private formBuilder: FormBuilder,
              private configFile: ConfigService
              )
  {
      this.siteKey = this.configFile.getConfig().siteKey;
      this.lingua = this.configFile.getConfig().defaultLanguage;
  }

  ngOnDestroy(): void {

  }


  ngOnInit(): void {

    //this.authStorage.setEstero('');

    // if(this.storageNewPagamento.getCodiceFiscale())
    // {
    //   this.authStorage.setEstero("valore");
    // }

    this.aFormGroup2 = this.formBuilder.group({
      recaptcha2: ['', Validators.required]
    });

    //Passaggio dei parametri sostituito con storage
    // this.sub = this.route.params.subscribe(params => {
    //   // (+) converts string 'id' to a number
    //   if(params['lingua']) this.lingua = params['lingua'];
    //   this.codiceEnte = params['codiceEnte'];
    //  });
    //Evito di far chiamare la pagina senza passare dal tasto nuovoPagamento
    this.codiceEnte = this.authStorage.getCodiceEnte();
    this.lingua = this.authStorage.getLingua();

    if (!Utility.isStringNullOrUndefinedOrEmpty(this.codiceEnte)){
        this.configStyle.init(this.codiceEnte);
    }
    else{
        this.router.navigate(['Errore']);
    }

    //Qui sono sicuro che una lingua e' settata
    this.translate.setDefaultLang(this.lingua);
    this.translate.use(this.lingua);
    this.configStyle.setConfigurazioneInLingua(this.lingua);

    //eventemitter registrato con header per cambio lingua del recaptcha
    if (this.eventMitterLang.subsVar==undefined) {
      this.eventMitterLang.subsVar = this.eventMitterLang.
      invokeAutenticazioneComponentFunction.subscribe((lang:string) => {
        this.onChangeLanguage(lang);
      });
    }

  }

  handleSuccess(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.authsvr.login(this.lingua).pipe(
      tap((isOk) => {
         if (isOk) this.router.navigate(['Pagamento']);
      }),
      //takeUntil(this.unSubscribe$)
    ).subscribe();
  }

  //Metodo registrato su eventemitter dell'header
  onChangeLanguage(lang: string) {
     this.lingua=lang;
  }

  get testDenominazioneEnte(): string {
    return this.configStyle.denominazioneEnte;
  }


}


