import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';

import { NgWizardConfig, THEME } from 'ng-wizard';
import { WizardDescrizione } from '../pagamento-spontaneo/descrittore-titolo';
import { Anagrafica } from '../_shared/models/anagrafica';
import { PagamentoService } from '../_shared/services/pagamento.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Utility } from '../_shared/utility';
import { ConfigurazioneStyleService } from '../_shared/config/configurazione-style.service';
import { HttpService } from '../_shared/http.service';
import { ConfigService } from '../_shared/config.service';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CausaleDto } from '../_shared/models/ente';

@Component({
  selector: 'app-esito-pagamento',
  templateUrl: './esito-pagamento.component.html',
  styleUrls: ['./esito-pagamento.component.css'],
})
export class EsitoPagamentoComponent implements OnInit {
  config: NgWizardConfig = {
    selected: 2,
    theme: THEME.arrows,
    anchorSettings: {
      anchorClickable: false,
    },
    toolbarSettings: {
      showNextButton: false,
      showPreviousButton: false,
    },
  };

  tabs: WizardDescrizione[] = [
    { idtab: 'Anagrafica', descrizione: 'Pagante' },
    { idtab: 'Pagamento', descrizione: 'Riepilogo dati ' },
    { idtab: 'Esito', descrizione: 'Esito transazione' },
  ];

  anagrafica: Anagrafica;
  paganteNonCoincide = false;
  disableLang: boolean;
  token: string;
  stato: string;
  idCarrello: string;
  idPagamento: number;
  linkFatturaPresente = false;
  lingua: string;
  codiceEnte: string;
  appEsitoVisible: boolean;
  siteKey: string;


  public validi = '|TA|TS|UN|';
  public esitoVisible = false;
  public isOK;
  public statoPagamento;
  public descrizioneEsito;
  private listaCausali: Array<CausaleDto> = [];

  get urlRicevuta(): string {
    // ALT 096
    return `${this.http.baseUrlApi}${this.configFile.getConfig().urlRicevuta}/${
      this.token
    }`;
    // return `${this.http.baseUrlApi}${Config.urlRicevuta}/44355c8f-53b5-4871-8d94-252357f84c48`;
  }
  get urlFattura(): string {
    // ALT 096
    return `${this.http.baseUrlApi}${this.configFile.getConfig().urlFattura}/${
      this.token
    }`;
    // return `${this.http.baseUrlApi}${Config.urlFattura}/44355c8f-53b5-4871-8d94-252357f84c48`;
  }

  constructor(
    private pagamentoSrv: PagamentoService,
    private route: ActivatedRoute,
    private router: Router,
    public configStyle: ConfigurazioneStyleService,
    private http: HttpService,
    private configFile: ConfigService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef
  ) {
    this.siteKey = this.configFile.getConfig().siteKey;
  }

  @BlockUI() blockUI: NgBlockUI;

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams.token;
    this.codiceEnte = this.route.snapshot.queryParams.codiceEnte;
    this.lingua = this.route.snapshot.queryParams.lingua;

    if (!Utility.isStringNullOrUndefinedOrEmpty(this.codiceEnte)) {
      this.configStyle.init(this.codiceEnte);
    }

    if (Utility.isStringNullOrUndefinedOrEmpty(this.token)) {
      this.router.navigate(['Errore']);
    }

    this.appEsitoVisible = true;

    this.pagamentoSrv.leggiCarrello(this.token).subscribe((response) => {
      if (response.sipaEsito.codiceEsito === 'KO') {
        this.router.navigate(['Errore']);
      } else {
        this.stato = response.sipaCarrello.stato;
        this.idCarrello = response.sipaCarrello.idCarrelloSorgente;

        //TEST attenzione gli itemCarrello sono tanti e non uno
        //this.idPagamento = response.sipaCarrello.itemCarrelloDto.idPagamento;

        this.anagrafica = Anagrafica.CreateFromCarrelloResponse(response);
        let localCausaleRaggruppamento: string;

        response.sipaCarrello.itemCarrelloDto.forEach((element) => {
          localCausaleRaggruppamento = decodeURIComponent(element.causaleRaggruppamento);
          let causale: CausaleDto = new CausaleDto();

          if (Utility.isStringNullOrUndefinedOrEmpty(element.causale))
          {
            causale.descCausale = decodeURIComponent(element.causaleRaggruppamento);
          } else {
            causale.descCausale = decodeURIComponent(element.causale);
          }
          causale.importoUnitario = element.importoUnitario;
          causale.quantita = element.quantitaItem.toString();
          causale.importoImposta = element.importoImposta.toString();

          let temp: number = element.importoUnitario * element.quantitaItem + element.importoImposta;
          causale.importo = temp.toString();

          this.listaCausali.push(causale);
        });

        this.anagrafica.causaleRaggruppamento = localCausaleRaggruppamento;
        this.anagrafica.pecSDI = response.sipaCarrello.clientePecSdi;
        this.anagrafica.codiceDestinatario = response.sipaCarrello.clienteDestSdi;

        if (this.anagrafica.cliente.codiceFiscale) {
          this.paganteNonCoincide = true;
        }
        this.linkFatturaPresente = false;

        //---->>>
        this.anagrafica.causale = decodeURIComponent(this.anagrafica.causale);

        this.anagrafica.cliente.indirizzo = decodeURIComponent(this.anagrafica.cliente.indirizzo);
        this.anagrafica.cliente.cognome = decodeURIComponent(this.anagrafica.cliente.cognome);

        if (this.anagrafica.cliente.cognome != null && this.anagrafica.cliente.cognome != 'undefined') {
          let temp = this.anagrafica.cliente.cognome;
          this.anagrafica.cliente.nome = temp.split('|')[1];
          this.anagrafica.cliente.cognome = temp.split('|')[0];

          if (this.anagrafica.cliente.nome == null || this.anagrafica.cliente.nome == 'undefined') {
            this.anagrafica.cliente.nome = '';
          }
        }

        this.anagrafica.cliente.localita = decodeURIComponent(this.anagrafica.cliente.localita);
        this.anagrafica.pagante.indirizzo = decodeURIComponent(this.anagrafica.pagante.indirizzo);
        this.anagrafica.pagante.cognome = decodeURIComponent(this.anagrafica.pagante.cognome);

        if (this.anagrafica.pagante.cognome != null && this.anagrafica.pagante.cognome != 'undefined')
        {
          let temp = this.anagrafica.pagante.cognome;
          this.anagrafica.pagante.nome = temp.split('|')[1];
          this.anagrafica.pagante.cognome = temp.split('|')[0];

          if (this.anagrafica.pagante.nome == null || this.anagrafica.pagante.nome == 'undefined') {
            this.anagrafica.pagante.nome = '';
          }
        }

        this.anagrafica.pagante.localita = decodeURIComponent(this.anagrafica.pagante.localita);

        if (this.anagrafica.pecSDI || this.anagrafica.codiceDestinatario) {
          //this.visibility = true;
          //this.appEsitoVisible = false;
        }
      }

      // Se la provincia e' EE - Estero bisogna selezionare il check e renderlo disable

      if (this.paganteNonCoincide) {
        //   //Se pagante non coincide bisogna vedere se
        //   //la provincia del cliente e' EE - Estero
        //   //In tal caso bisogna selezionare il check e renderlo disable
      }
    });
    console.log('_______', this.anagrafica);
  } // END oninit


  // calcoloTotale(importoUnitario: any, importoIva: any, quantita: any) {
  //   let totale;
  //   totale = ((importoNetto * 1) * (aliquotaIva * 1) / 100) + importoNetto * 1;
  //   totale = totale * 1 * (quantita * 1);
  //   return totale;
  // }
}
