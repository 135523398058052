import { Injectable } from '@angular/core';
import ConfigStyle from '../../../assets/config-style.json';
import { ConfigurazioneStyle } from './configurazione-style';
import { Utility } from '../utility.js';
import { AuthStorageService } from '../auth-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurazioneStyleService {

  private readonly KEY_DEFAULT_STYLE: string = 'CCIAA_ST';

  private _logo: string;
  private _style: ConfigurazioneStyle;
  private _denominazioneEnte: string;
  private _denominazioneFooter: string;
  private _linkPersonale: string;
  private _flagInt: string;

  get style(): ConfigurazioneStyle {
    // if (!this._style){
    //   this._style = ConfigStyle.CAMCOMS.find(s => s.camcomCod === this.KEY_DEFAULT_STYLE);
    // }
    return this._style;
  }

  get denominazioneEnte(): string {
    return this._denominazioneEnte;
  }

  get denominazioneFooter(): string {
    return this._denominazioneFooter;
  }

  get linkPersonale(): string {
    return this._linkPersonale;
  }

  get logo(): string {
    if (Utility.isStringNullOrUndefinedOrEmpty(this._logo)){
       this._logo = 'generic.png';
    }
    return this._logo;
  }

  get flagInt(): string
  {
    return this._flagInt;
  }

  constructor( private storageSrv: AuthStorageService) {}

  init(camcomCodRichiesto: string): void {
    if (!ConfigStyle || !ConfigStyle.CAMCOMS) {
      throw new Error('Foglio di Style non trovato');
    }

    ConfigStyle.CAMCOMS.forEach((element: ConfigurazioneStyle) => {
      if (element.camcomCod === camcomCodRichiesto) {
        this._style = element;
      }
    });

    if (!Utility.isStringNullOrUndefinedOrEmpty(this._style.logo)) {
      if(this.storageSrv.getLingua() && this.storageSrv.getLingua()=='de')
      {
        this._logo = this._style.logo_DE;
      }else{
        this._logo = this._style.logo;
      }
    }

    if (!Utility.isStringNullOrUndefinedOrEmpty(this._style.denominazioneEnte)) {

      if(this.storageSrv.getLingua() && this.storageSrv.getLingua()=='de')
      {
        this._denominazioneEnte = this._style.denominazioneEnte_DE;
      }else{
        this._denominazioneEnte = this._style.denominazioneEnte;
      }
    }

    if (!Utility.isStringNullOrUndefinedOrEmpty(this._style.denominazioneFooter)) {
      this._denominazioneFooter = this._style.denominazioneFooter;
    }

    if (this._style.flagInt) {
      this._flagInt = this._style.flagInt;
    }


  }

  //configura lingua
  setConfigurazioneInLingua(lingua: string)
  {
    if (lingua=='de'){
      this._logo = this._style.logo_DE;
      this._denominazioneEnte = this._style.denominazioneEnte_DE;
      this._denominazioneFooter = this._style.denominazioneFooter_DE;
    }
    else
    {
      //Default
      this._denominazioneEnte = this._style.denominazioneEnte;
      this._logo = this._style.logo;
      this._denominazioneFooter = this._style.denominazioneFooter;
    }
  }
}
