<div class="card mb-4">
  <div class="card-body">
    <div class="form-row" [ngModelGroup]="nameModelGroup">
      <div class="form-group col-sm-6 col-12" [class.cross-validation-error]="checkDestinatario() || checkData()">
        <label [for]="nameModelGroup + 'CodiceDestinatario'">{{ 'ANAGRAFICA.CODICE_DESTINATARIO_SDI' |
          translate}}</label>
        <input type="text" class="form-control" [id]="nameModelGroup + 'CodiceDestinatario'" maxlength=7
          [(ngModel)]="anagrafica.codiceDestinatario" name="codiceDestinatario" #codiceDestinatario
          pattern="[A-Z0-9]{6,7}" [required]="checkData()"
          (ngModelChange)="anagrafica.codiceDestinatario=$event.toUpperCase(); controllo('codiceDestinatario')"
          placement="bottom" ngbTooltip="{{ 'ANAGRAFICA.CODICE_DESTINATARIO_SDI_TOOLTIP' | translate}}" />
      </div>
      <div class="form-group col-sm-6 col-12" [class.cross-validation-error]="checkPec() || checkData()">
        <label [for]="nameModelGroup + 'inputPecSDI'">{{ 'ANAGRAFICA.PEC_SDI' | translate}}</label>
        <input type="text" #inputPecSDI class="form-control" [id]="nameModelGroup + 'inputPecSDI'"
          [(ngModel)]="anagrafica.pecSDI" (ngModelChange)="controllo('inputPecSDI')" name="pecSDI" email [required]="checkData()" />
      </div>
    </div>
  </div>
</div>
