<ngb-accordion #acc="ngbAccordion"
  activeIds="panel"
  (panelChange)="clickAccordion($event)">
  <ngb-panel id="panel" cardClass="mb-4">

    <ng-template ngbPanelTitle>
      <label>
        <fa-icon class="fa fa-plus" [icon]="faPlus" *ngIf="!isOpen"></fa-icon>
        <fa-icon class="fa fa-plus" [icon]="faMinus" *ngIf="isOpen"></fa-icon>&nbsp;&nbsp;
        {{ 'ANAGRAFICA.DATI_ANAGRAFICI.TITLE' | translate }} {{suffixTitle}}
      </label>
    </ng-template>
    <ng-template ngbPanelContent [ngModelGroup]="nameModelGroup">
        <div class="form-row">
          <div class="col-sm-12 col-12">
            <label [for]="nameModelGroup+'checkEstero'">{{ 'ANAGRAFICA.DATI_ANAGRAFICI.CHECK' | translate }}</label>
                <input type="checkbox" class="form-control-check" [id]="nameModelGroup+'checkEstero'" [(ngModel)]="persona.checkEstero"
                  name="checkEstero"
                  (click)="clickEstero(nameModelGroup)">
          </div>
          <div class="form-group col-sm-6 col-12" [class.cross-validation-error]="hasError('codiceFiscale')">
            <label [for]="nameModelGroup+'CodiceFiscale'">{{ 'ANAGRAFICA.DATI_ANAGRAFICI.CODICE_FISCALE' | translate }} *</label>
            <input type="text"
              class="form-control"
              [id]="nameModelGroup+'CodiceFiscale'"
              placeholder="{{ 'ANAGRAFICA.DATI_ANAGRAFICI.CODICE_FISCALE_PLACEHOLDER' | translate  }} {{suffixTitle}}"
              [(ngModel)]="persona.codiceFiscale"
              name="codiceFiscale"
              required
              maxlength="35"
              [appCodiceFiscaleOrPIva] = this.checkEstero
              >
          </div>
          <div class="form-group col-sm-6 col-12" [class.cross-validation-error]="hasError('partitaIva')">
            <label [for]="nameModelGroup+'PartitaIva'">{{ 'ANAGRAFICA.DATI_ANAGRAFICI.PARTITA_IVA' | translate }}</label>
            <input type="text"
              class="form-control"
              [id]="nameModelGroup+'PartitaIva'"
              placeholder="{{ 'ANAGRAFICA.DATI_ANAGRAFICI.PARTITA_IVA_PLACEHOLDER' | translate  }} {{suffixTitle}}"
              [(ngModel)]="persona.partitaIva"
              name="partitaIva"
              ng-pattern="/^[0-9]*$/"
              maxlength="11" 
              >
          </div>
          <div class="form-group col-sm-6 col-12" [class.cross-validation-error]="hasError('cognome')">
            <label [for]="nameModelGroup+'Cognome'">{{ 'ANAGRAFICA.DATI_ANAGRAFICI.COGNOME' | translate }} *</label>
            <input type="text"
              class="form-control"
              [id]="nameModelGroup+'Cognome'"
              placeholder="{{ 'ANAGRAFICA.DATI_ANAGRAFICI.COGNOME_PLACEHOLDER' | translate  }} {{suffixTitle}}"
              [(ngModel)]="persona.cognome"
              name="cognome"
              required
              pattern= "^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð% '.&]*$"
              >
          </div>
          <div class="form-group col-sm-6 col-12" [class.cross-validation-error]="hasError('nome')">
            <label [for]="nameModelGroup+'Nome'">{{ 'ANAGRAFICA.DATI_ANAGRAFICI.NOME' | translate }}</label>
            <input type="text"
              class="form-control"
              [id]="nameModelGroup+'Nome'"
              placeholder="{{ 'ANAGRAFICA.DATI_ANAGRAFICI.NOME_PLACEHOLDER' | translate  }} {{suffixTitle}}"
              [(ngModel)]="persona.nome"
              name="nome"
              pattern= "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ']*$"
              >
              <!-- pattern= "^[a-zA-Z ]*$" -->
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-6 col-12" [class.cross-validation-error]="hasError('indirizzo')">
            <label [for]="nameModelGroup+'Indirizzo'">{{ 'ANAGRAFICA.DATI_ANAGRAFICI.RESIDENZA' | translate }} *</label>
            <input type="text" class="form-control"
               [id]="nameModelGroup+'Indirizzo'"
               placeholder="{{ 'ANAGRAFICA.DATI_ANAGRAFICI.RESIDENZA_PLACEHOLDER' | translate  }} {{suffixTitle}}"
               [(ngModel)]="persona.indirizzo"
               name="indirizzo"
               required
               ng-pattern="/^@[a-zA-Z0-9]+(?:,\s*@[a-zA-Z0-9]+)*$/"
               >
          </div>
          <div class="form-group col-sm-3 col-4" [class.cross-validation-error]="hasError('civico')">
            <label [for]="nameModelGroup+'Civico'">{{ 'ANAGRAFICA.DATI_ANAGRAFICI.CIVICO' | translate }}  *</label>
            <input type="text" class="form-control"
               [id]="nameModelGroup+'Civico'"
               [(ngModel)]="persona.civico"
               placeholder="{{ 'ANAGRAFICA.DATI_ANAGRAFICI.CIVICO' | translate | lowercase }}"
               name="civico"
               maxlength = "8"
               required>
          </div>
          <div class="form-group col-sm-3 col-12" [class.cross-validation-error]="hasError('cap')">
            <label [for]="nameModelGroup+'CAP'">{{ 'ANAGRAFICA.DATI_ANAGRAFICI.CAP' | translate }} *</label>
            <input type="text" class="form-control"
               [id]="nameModelGroup+'CAP'"
               [(ngModel)]="persona.cap"
               placeholder="{{ 'ANAGRAFICA.DATI_ANAGRAFICI.CAP' | translate | lowercase  }} {{suffixTitle}}"
               name="cap"
               maxlength="5"
               required>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-6 col-12" [class.cross-validation-error]="hasError('localita')">
            <label [for]="nameModelGroup+'Localita'">{{ 'ANAGRAFICA.DATI_ANAGRAFICI.COMUNE' | translate }} *</label>
            <input type="text" class="form-control"
               [id]="nameModelGroup+'Localita'"
               placeholder="{{ 'ANAGRAFICA.DATI_ANAGRAFICI.COMUNE_PLACEHOLDER' | translate  }} {{suffixTitle}}"
               [(ngModel)]="persona.localita"
               name="localita"
               required
               ng-pattern="/^@[a-zA-Z0-9]+(?:,\s*@[a-zA-Z0-9]+)*$/"
               >
          </div>
          <div class="form-group col-sm-3 col-12" [class.cross-validation-error]="hasError('provincia')">
            <label [for]="nameModelGroup+'Provincia'">{{ 'ANAGRAFICA.DATI_ANAGRAFICI.PROVINCIA' | translate }} *</label>
              <select #customSelect [id]="nameModelGroup+'Provincia'" class="form-control " (change)="changeProvincia(nameModelGroup, $event.target.value)"
                [id]="nameModelGroup+'Provincia'"
                [(ngModel)]="persona.provincia"
                name="provincia"
                required>
                <!--option *ngFor="let provincia of provinceEnabled" [value]="provincia.codice">{{provincia.codice}} - {{provincia.nome}}</option-->
                <option *ngFor="let provincia of provinceEnabled" [value]="provincia.codice" >{{provincia.codice}}</option>
              </select>



          </div>
          <div class="form-group col-sm-3 col-12" [class.cross-validation-error]="hasError('provincia')">
            <label [for]="nameModelGroup+'siglaProv'">*</label>
              <input type="text" class="form-control"
               [id]="nameModelGroup+'siglaProv'" [(ngModel)]="persona.siglaProv"
               name="siglaProv"
               readonly
               >


          </div>
        </div>
          <div class="form-row">
            <div class="form-group col-sm-6 col-12 " [class.cross-validation-error]="hasError('email')">
              <label [for]="nameModelGroup+'Email'">{{ 'ANAGRAFICA.DATI_ANAGRAFICI.EMAIL' | translate }} *</label>
              <input type="text" class="form-control"
                 [id]="nameModelGroup+'Email'"
                 placeholder="{{ 'ANAGRAFICA.DATI_ANAGRAFICI.EMAIL_PLACEHOLDER' | translate  }} {{suffixTitle}}"
                 [(ngModel)]="persona.email"
                 name="email"
                 required
                 email
                 >
            </div>
          </div>
          <label>
            {{ 'ANAGRAFICA.INDIRIZZO_SPECIFICATO' | translate }}<br>
            {{ 'ANAGRAFICA.INDIRIZZO_NON_VALIDO' | translate }}
          </label>


    </ng-template>
  </ngb-panel>
</ngb-accordion>
<!-- <option *ngIf="!verificaEstero(nameModelGroup)" [value]="EE" selected>EE - Estero</option> -->
