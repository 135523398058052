import { Component, OnInit, ViewChild } from '@angular/core';
import { NgWizardService } from 'ng-wizard';
import { Anagrafica } from 'src/app/_shared/models/anagrafica';
import { NgForm } from '@angular/forms';
import { ModalCheckErrorComponent } from 'src/app/modals/modal-check-error/modal-check-error.component';
import { FormDataAnagraficaStorage } from 'src/app/_shared/services/form-data.service';


@Component({
  selector: 'app-anagrafica2',
  templateUrl: './anagrafica2.component.html',
  styleUrls: ['./anagrafica2.component.css']
})
export class Anagrafica2Component implements OnInit {
  // public isCollapsed = true;
  // faPlus = faPlus;
  // faMinus = faMinus;

  anagrafica: Anagrafica;
  @ViewChild(ModalCheckErrorComponent) modalCheckError: ModalCheckErrorComponent;
  @ViewChild('anagraficaForm') anagraficaForm: NgForm;

  constructor(
    private formDataStorage: FormDataAnagraficaStorage,
    private ngWizardService: NgWizardService) {
      window.scroll(0,0);
    }

  ngOnInit(): void {
    this.anagrafica = this.formDataStorage.data;
  }

  showNextStep(event?: Event): void {
    this.ngWizardService.next();
  }

  showPreviousStep(event?: Event): void {
    this.ngWizardService.previous();
  }

  onSubmit(anagraficaForm: NgForm): void {

    if (anagraficaForm.invalid){
      // console.log(anagraficaForm);
      this.modalCheckError.open();
    }else{
      this.showNextStep();
    }
    // this.showNextStep();
  }

}
