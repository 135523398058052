import { AfterContentChecked, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { EventEmitter } from 'protractor';
import { FormComponent } from 'src/app/_shared/form-component';
import { Anagrafica } from 'src/app/_shared/models/anagrafica';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-info-codice-destinatario',
  templateUrl: './info-codice-destinatario.component.html',
  styleUrls: ['./info-codice-destinatario.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class InfoCodiceDestinatarioComponent extends FormComponent implements OnInit, AfterContentChecked {

  @Input() anagrafica: Anagrafica;
  @ViewChild('codiceDestinatario') codiceDestinatario: ElementRef;
  @ViewChild('inputPecSDI') inputPecSDI: ElementRef;
  @Input() datiObbligatori: any; //A.A. controllo dei campi quando azionati

  constructor() {
    super();
  }

  ngOnInit(): void {

  }

  ngAfterContentChecked(): void {
  }

  controllo(campo: string) {

    if (this.isDetail) {
      //this.disableControls();
      this.inputPecSDI.nativeElement.disabled = true;
      this.codiceDestinatario.nativeElement.disabled = true;
    }
    if (campo == 'codiceDestinatario') {
      //Se vuoto abilito i 2 campi
      if (this.anagrafica.codiceDestinatario == '') {
        this.inputPecSDI.nativeElement.disabled = false;
      }
      else {
        this.inputPecSDI.nativeElement.disabled = true;

      }
    }

    if (campo == 'inputPecSDI') {
      //Se vuoto abilito i 2 campi
      if (this.anagrafica.pecSDI == '') {
        this.codiceDestinatario.nativeElement.disabled = false;
      }
      else {
        this.codiceDestinatario.nativeElement.disabled = true;

      }
    }

  }

  checkPec(): boolean {
    let regEmail = /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.inputPecSDI) {

      if (this.anagrafica.pecSDI) {
        if (regEmail.test(this.anagrafica.pecSDI)) {
          return false;
        }
        else {
          return true;
        }
      }

    }
  }


  checkDestinatario(): boolean {
    let regCodice = /[A-Z0-9]{6,7}/;

    if (this.codiceDestinatario) {

      if (this.anagrafica.codiceDestinatario) {
        if (regCodice.test(this.anagrafica.codiceDestinatario)) {
          return false;
        }
        else {
          return true;
        }
      }

    }
  }

  //A.A. setto i campi SDI obbligatori se i campi dati fatturazioni sono selezionati
  checkData(): boolean {
    if (this.anagrafica.codiceDestinatario || this.anagrafica.pecSDI) {
      return false; 
    }
    return this.datiObbligatori === true;
  }
}
