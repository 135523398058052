import { AuthRequest } from './auth-request';

export class StatoPagamentoRequest extends AuthRequest {
  idStatoPagamento: string;
  idCarrelloSorgente: string;

  static CreateFromVM(
    statoPagamento: string,
    idCarrelloSorgente: string,
    sipaPlus: string
  ): StatoPagamentoRequest {
    const vm: StatoPagamentoRequest = new StatoPagamentoRequest();
    vm.idCarrelloSorgente = idCarrelloSorgente;
    vm.idStatoPagamento = statoPagamento;
    return vm;
  }
}
