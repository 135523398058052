import { APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PagamentoSpontaneoComponent } from './pagamento-spontaneo/pagamento-spontaneo.component';
import { AnagraficaComponent } from './pagamento-spontaneo/wizards/anagrafica/anagrafica.component';
import { PagamentoComponent } from './pagamento-spontaneo/wizards/pagamento/pagamento.component';
import { EsitoComponent } from './pagamento-spontaneo/wizards/esito/esito.component';

import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { Anagrafica2Component } from './pagamento-spontaneo/wizards/anagrafica2/anagrafica2.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AutenticazioneComponent } from './autenticazione/autenticazione.component';
import { ModalCheckErrorComponent } from './modals/modal-check-error/modal-check-error.component';
import { AnagraficaPersonaComponent } from './pagamento-spontaneo/wizards/anagrafica/anagrafica-persona/anagrafica-persona.component';
import { InfoPagamentoComponent } from './pagamento-spontaneo/wizards/anagrafica/info-pagamento/info-pagamento.component';
import { InfoImportoComponent } from './pagamento-spontaneo/wizards/anagrafica/info-importo/info-importo.component';
import { InfoCodiceDestinatarioComponent } from './pagamento-spontaneo/wizards/anagrafica/info-codice-destinatario/info-codice-destinatario.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CheckRegolamentoComponent } from './pagamento-spontaneo/wizards/anagrafica/check-regolamento/check-regolamento.component';
import { PageErrorComponent } from './page-error/page-error.component';
import { CodiceFiscaleOrPIvaDirective } from './pagamento-spontaneo/wizards/anagrafica/anagrafica-persona/codicefiscale-or-piva.directive';
import { EsitoPagamentoComponent } from './esito-pagamento/esito-pagamento.component';
import { DownloadLinksComponent } from './download-links/download-links.component';
import { HeaderEsitoComponent } from './esito-pagamento/header-esito/header-esito.component';
import { BlockUIModule } from 'ng-block-ui';
import { ModalComponent } from './modals/modal/modal.component';
import { PositivoDirective } from './pagamento-spontaneo/wizards/anagrafica/info-importo/info-importo.directive';
import { ConfigService } from './_shared/config.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NuovoPagamentoComponent } from './nuovoPagamento/nuovoPagamento.component';
import { CaptchaEsitoComponent } from './esito-pagamento/captcha-esito/captchaEsito.component';
import { InfoPagamentoPlusComponent } from './pagamento-spontaneo/wizards/anagrafica/info-pagamento-plus/info-pagamento-plus.component';
import { InfoImportoTwo_decimalDirective } from './pagamento-spontaneo/wizards/anagrafica/info-importo/info-importo-two_decimal.directive';
import { InfoPagamentoPlusTwo_decimalDirective } from './pagamento-spontaneo/wizards/anagrafica/info-pagamento-plus/info-pagamento-plus-two_decimal.directive';
import { HeaderEsitoPlusComponent } from './esito-pagamento/header-esito-plus/header-esito-plus.component';

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.arrows,
};

export function initializeApp(appConfig: ConfigService) {
  return () => appConfig.init();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CaptchaEsitoComponent,
    FooterComponent,
    PagamentoSpontaneoComponent,
    AnagraficaComponent,
    PagamentoComponent,
    EsitoComponent,
    Anagrafica2Component,
    AutenticazioneComponent,
    ModalCheckErrorComponent,
    AnagraficaPersonaComponent,
    InfoPagamentoComponent,
    InfoImportoComponent,
    InfoCodiceDestinatarioComponent,
    CheckRegolamentoComponent,
    PageErrorComponent,
    CodiceFiscaleOrPIvaDirective,
    EsitoPagamentoComponent,
    DownloadLinksComponent,
    HeaderEsitoComponent,
    ModalComponent,
    PositivoDirective,
    NuovoPagamentoComponent,
    InfoPagamentoPlusComponent,
    HeaderEsitoPlusComponent,
    InfoImportoTwo_decimalDirective,
    InfoPagamentoPlusTwo_decimalDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    NgWizardModule.forRoot(ngWizardConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      //defaultLanguage: environment.defaultLanguage
    }),
    FontAwesomeModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    HttpClientModule,
    BlockUIModule.forRoot(),
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
// }
