<div class="footer" [ngStyle]="{'background-color': style?.footerColorBg}">
  <div class="row align-items-center">
    <div class="col-sm-8 text-center">
      <h6>
        <span class="label" [ngStyle]="{'color': style?.footerColorFg}">
        <!--{{style?.denominazioneFooter}}-->
        {{ nameDenominazioneFooter }}
        </span></h6>
    </div>
    <div class="col-sm-4 text-center">
       <img class="img-fluid .img-thumbnail" src="assets/img/logo-infocamere.png">
    </div>
  </div>
</div>

