import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SipaPlusStorageService {
  private readonly KEY_SIPAPLUS: string = 'sipaPlus';


  get storage(): Storage { return sessionStorage; }

  constructor() {

  }

  getSipaPlus(): string {
    return this.storage.getItem(this.KEY_SIPAPLUS);
  }

  setSipaPlus(token: string): void {
    this.storage.setItem(this.KEY_SIPAPLUS, token);
  }

  clearAll()
  {
    this.storage.clear();
  }

  clear()
  {
    this.setSipaPlus(null);
  }
}
