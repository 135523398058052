<div class="card mb-4">
    <div class="card-body" [ngModelGroup]="nameModelGroup">
        <div class="form-row">
            <!-- <div class="form-group col-sm-6" *ngIf="enteDescrizione"  [class.cross-validation-error]="hasError('ente')">
        <label [for]="nameModelGroup+'Ente'">Ente</label>
        <select placeholder="Ente"
            class="form-control"
            [id]="nameModelGroup+'Ente'"
            [ngModel]="enteDescrizione"
            name="ente"
            required>
          <option [value]="enteDescrizione">{{enteDescrizione}}</option>
        </select>
      </div> -->
            <div class="form-group col-sm-6" *ngIf="applServizioDescrizione">
                <label [for]="nameModelGroup+'Servizio'">{{ 'INFO_PAGAMENTO.SERVIZIO' | translate }}</label>
                <select [ngModel]="applServizioDescrizione" [id]="nameModelGroup+'Servizio'" placeholder="Elenco dei servizi / Applicazioni dell'ente" class="form-control" name="servizio" required>
          <option [value]="applServizioDescrizione">
            <div *ngIf="translate.currentLang=='it'">
              {{
                applServizioDescrizione.split('#')[0].split(':')[1]!=null?applServizioDescrizione.split('#')[0].split(':')[1]:applServizioDescrizione
              }}
            </div>
            <div *ngIf="translate.currentLang=='de'">
              {{
                applServizioDescrizione.split('#')[1]!=null?applServizioDescrizione.split('#')[1].split(':')[1]:applServizioDescrizione
              }}
            </div>
          </option>
        </select>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-sm-12">
                <label [for]="nameModelGroup+'Causale'">{{ 'INFO_PAGAMENTO.CAUSALE' | translate }} *</label>
                <textarea [(ngModel)]="causale" [id]="nameModelGroup+'Causale'" name="causale" class="form-control" rows="3" required placeholder="{{ 'INFO_PAGAMENTO.PLACEHOLDER' | translate }}" maxlength="120"></textarea>
            </div>
        </div>
    </div>
</div>
