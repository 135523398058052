import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { AuthStorageService } from '../auth-storage.service';
import { Ente } from '../models/ente';
import { Utility } from '../utility';
import { EnteRequest } from '../models/dtos/ente-request';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { ConfigurazioneStyleService } from '../config/configurazione-style.service';
import { Router } from '@angular/router';
import { EnteResponse } from '../models/dtos/ente-response';
import { TranslateService } from '@ngx-translate/core';
import { SipaPlusStorageService } from '../sipaplus-storage.service';
// import EntiResponseFake from '../../../assets/enti-response-fake.json';

@Injectable({
  providedIn: 'root'
})
export class EntiService {

  private static readonly DEFINIZIONE_ST: string = 'CCIAA_ST';

  private hasEnti = new BehaviorSubject<boolean>(false);
  public hasEnti$ = this.hasEnti.asObservable();
  private isLoading = false;

  private _entiInMemory: Ente[];
  get entiInMemory(): Ente[] {
    if ((!this._entiInMemory || !this._entiInMemory[0]) && !this.isLoading) {
      this.loadEnti().subscribe();
    }
    return this._entiInMemory;
  }


  get isUserST(): boolean {
    //return this.authStorage.getCodiceEnte() === EntiService.DEFINIZIONE_ST;
    return false;
  }

  constructor(
    private httpApi: HttpService,
    private authStorage: AuthStorageService,
    private configStyle: ConfigurazioneStyleService,
    private router: Router,
    private sipaPlusStorage: SipaPlusStorageService) {
  }

  loadEnti(codiceEnte?: string, lingua?:string): Observable<void> {
      return new Observable<void>((observer: Observer<void>) => {
      if (Utility.isStringNullOrUndefinedOrEmpty(codiceEnte)) {
        codiceEnte = this.authStorage.getCodiceEnte();
      }

     // debugger;
      this.isLoading = true;
      this.httpApi.leggiEnti(EnteRequest.Create(codiceEnte, this.authStorage.getToken(), this.authStorage.getGuid(), lingua))
        .subscribe(
          (response: EnteResponse) => this.handleEnteResponse(response, codiceEnte, observer),
          error => {
            console.error(error);
          },
          () => { observer.complete();}
        );
    });
  }

  private handleEnteResponse(response: EnteResponse, codiceEnte: string, observer?: Observer<void>): void {
    let test: any;
    test = response.sipaEsito;
    if (!response.sipaEsito) {
      console.log('Esito non valorizzato');
      this.router.navigate(['Errore']);
      return;
    }
    if (response.sipaEsito.codiceEsito === 'KO') {
      console.log('Esito KO');
      this.router.navigate(['Errore']);
      return;
    }
    if (this.isUserST) {
      this._entiInMemory = response.elencoEnti;
    }
    else {
      this._entiInMemory = new Array<Ente>(response.elencoEnti.find(ente => ente.codiceEnte === codiceEnte));
      this.sipaPlusStorage.setSipaPlus(response.elencoEnti[0].abilitatoSipaPlus);
    }
    this.hasEnti.next(true);

    this.configStyle.init(codiceEnte);

    // if (this._entiInMemory[0].imglogo) {
    //   console.log ("immagine da db = " + this._entiInMemory[0].imglogo);
    //   this.configStyle.init(codiceEnte, this._entiInMemory[0].imglogo);
    // }
    // else {
    //   this.configStyle.init(codiceEnte, '');
    // }

    observer?.next();
  }
}


