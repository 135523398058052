<div class="card mt-4 mb-4">
  <div class="card-body">
    <div class="form-row">
      <div class="col-sm-6 text-center">
        <!--Si è verificato un errore!!-->
        {{ 'ERROR_PAGE' | translate }}!!!
      </div>
    </div>
  </div>
</div>
