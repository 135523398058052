import { AuthRequest } from './auth-request';
import { Anagrafica } from '../anagrafica';
import { Utility } from '../../utility';
import { element } from 'protractor';

export class PagamentoRequest extends AuthRequest {
  sipaCodiceEnte: string;
  sipaToken: string;
  sipaGuid: string;
  sipaCarrello: SipaCarrello = new SipaCarrello();
  pagante: Pagante = new Pagante();
  contabilizzazioneDto: Cliente = new Cliente();
  lingua: string;

  static stringToNumber(a: string): number {
    let ston = +a;
    return ston;
  }

  private static CreateFromVMPlus(vm: Anagrafica, lingua: string): ItemCarrello[] {
    let output: Array<ItemCarrello> = [];

    vm.listaCausali.forEach((element) => {
      const item = new ItemCarrello();
      item.causale = element.descCausale;
      const importoNetto: number = +element.importo;
      item.importo = importoNetto;
      item.importoUnitario = importoNetto; //TEST
      item.aliquotaIva = element.aliquota; //da controllare
      const quantitaNumber: number = +element.quantita;
      item.quantitaItem = quantitaNumber;
      item.importoTotale = element.totale;
      item.pagamentoSpontaneo = element.fSpontaneo; //da controllare
      item.tipoContabilizzazione = element.codiceTipoContabilizzazione;
      item.causaleRaggruppamento = element.causaleRaggruppamento;
      item.codiceEnte = element.codiceEnte;
      output.push(item);
    });
    return output;
  }

  static CreateFromVM(vm: Anagrafica, lingua: string, sipaPlus: string): PagamentoRequest
  {
    const output = new PagamentoRequest();
    let listaCarrello: Array<ItemCarrello> = [];;
    //
    //TEST vm.abilitatoSipaPlus
    //
    if (sipaPlus == '1') {
      listaCarrello = this.CreateFromVMPlus(vm, lingua);
    }
    else
    {
      let item = new ItemCarrello();
      item.causale= encodeURIComponent(vm.causale);
      item.codiceEnte = vm.codiceEnte;
      item.importo = vm.importo;
      item.importoTotale = vm.importoTotale;
      item.importoUnitario = vm.importo;
      item.quantitaItem = vm.quantitaItem;
      item.causaleRaggruppamento = encodeURIComponent(vm.causale);
      item.pagamentoSpontaneo = "0";
      //item.fkIdApplicazione = vm.idServizio;
      listaCarrello.push(item);
    }
    output.sipaCarrello.itemCarrelloDto = listaCarrello;
    output.lingua = lingua;
    output.sipaCarrello.cdp = vm.cdp;
    output.sipaCarrello.cpk = vm.cpk;
    output.contabilizzazioneDto.tipoContabilizzazione = vm.tipoContabilizzazione;

    output.pagante.paganteCap = vm.pagante.cap;
    output.pagante.paganteCfPiva = vm.pagante.codiceFiscale;
    if (!Utility.isStringNullOrUndefinedOrEmpty(vm.pagante.partitaIva))
      output.pagante.pagantePiva = vm.pagante.partitaIva;
    output.pagante.paganteCivico = vm.pagante.civico;
    output.pagante.paganteComune = encodeURIComponent(vm.pagante.localita);
    output.pagante.paganteNome = encodeURIComponent(vm.pagante.nome);

    //Per bug nome undefined su esito pagamento
    //output.pagante.paganteDenominazione = encodeURIComponent(vm.pagante.cognome);
    if (Utility.isStringNullOrUndefinedOrEmpty(vm.pagante.nome))
      output.pagante.paganteDenominazione = encodeURIComponent(
        vm.pagante.cognome
      );
    else
      output.pagante.paganteDenominazione =
        encodeURIComponent(vm.pagante.cognome) +
        '|' +
        encodeURIComponent(vm.pagante.nome);

    output.pagante.paganteEmail = vm.pagante.email;
    output.pagante.paganteIndirizzo = encodeURIComponent(vm.pagante.indirizzo);
    output.pagante.paganteSglPrv = vm.pagante.provincia;

    //ABA Attenzione al contenitore
    output.contabilizzazioneDto.clienteDestSdi = vm.codiceDestinatario;
    output.contabilizzazioneDto.clientePecSdi = vm.pecSDI;

    if (vm.cliente && vm.cliente?.codiceFiscale) {
      output.contabilizzazioneDto.clienteCap = vm.cliente.cap;
      output.contabilizzazioneDto.clienteCfPiva = vm.cliente.codiceFiscale;
      if (!Utility.isStringNullOrUndefinedOrEmpty(vm.cliente.partitaIva))
        output.contabilizzazioneDto.clientePiva = vm.cliente.partitaIva;
      output.contabilizzazioneDto.clienteCivico = vm.cliente.civico;
      output.contabilizzazioneDto.clienteComune = encodeURIComponent(vm.cliente.localita);
      output.contabilizzazioneDto.clienteNome = encodeURIComponent(vm.cliente.nome);
      //output.contabilizzazioneDto.clienteDenominazione = encodeURIComponent(vm.cliente.cognome);

      if (Utility.isStringNullOrUndefinedOrEmpty(vm.cliente.nome))
        output.contabilizzazioneDto.clienteDenominazione = encodeURIComponent(
          vm.cliente.cognome
        );
      else
        output.contabilizzazioneDto.clienteDenominazione =
          encodeURIComponent(vm.cliente.cognome) +
          '|' +
          encodeURIComponent(vm.cliente.nome);

      output.contabilizzazioneDto.clienteEmail = vm.cliente.email;
      output.contabilizzazioneDto.clienteIndirizzo = encodeURIComponent(vm.cliente.indirizzo);
      output.contabilizzazioneDto.clienteSglPrv = vm.cliente.provincia;
    } else {
      output.contabilizzazioneDto.clienteCap = vm.pagante.cap;
      output.contabilizzazioneDto.clienteCfPiva = vm.pagante.codiceFiscale;
      if (!Utility.isStringNullOrUndefinedOrEmpty(vm.pagante.partitaIva))
        output.contabilizzazioneDto.clientePiva = vm.pagante.partitaIva;
      output.contabilizzazioneDto.clienteCivico = vm.pagante.civico;
      output.contabilizzazioneDto.clienteComune = encodeURIComponent(vm.pagante.localita);
      //output.contabilizzazioneDto.clienteDenominazione = encodeURIComponent(vm.pagante.cognome);

      if (Utility.isStringNullOrUndefinedOrEmpty(vm.pagante.nome))
        output.contabilizzazioneDto.clienteDenominazione = encodeURIComponent(vm.pagante.cognome);
      else
        output.contabilizzazioneDto.clienteDenominazione =
          encodeURIComponent(vm.pagante.cognome) +
          '|' +
          encodeURIComponent(vm.pagante.nome);

      output.contabilizzazioneDto.clienteNome = encodeURIComponent(vm.pagante.nome);
      output.contabilizzazioneDto.clienteEmail = vm.pagante.email;
      output.contabilizzazioneDto.clienteIndirizzo = encodeURIComponent(vm.pagante.indirizzo);
      output.contabilizzazioneDto.clienteSglPrv = vm.pagante.provincia;
    }

    return output;
  }
}

export class SipaCarrello {
  cdp: string;
  cpk: string;
  itemCarrelloDto: ItemCarrello[];
  idCarrelloSorgente: string;
  dtOraInsert: number;
  dtOraUpdate: number;
  dtOraApertura: number;
  dtOraChiusura: number;
  fkIdApplicazione: number;
  idCarrello: number;
  pagamentoDto: any;
  paganteCap: string;
  paganteCfPiva: string;
  pagantePiva: string;
  paganteCivico: string;
  paganteComune: string;
  paganteDenominazione: string;
  paganteEmail: string;
  paganteIndirizzo: string;
  paganteNome: string;
  paganteSglPrv: string;

  clienteCap: string;
  clienteCfPiva: string;
  clientePiva: string;
  clienteCivico: string;
  clienteComune: string;
  clienteDenominazione: string;
  clienteEmail: string;
  clienteIndirizzo: string;
  clienteNome: string;
  clienteSglPrv: string;

  stato: string;

  applicazioneDesc: string;
  enteDesc: string;
  clientePecSdi: string;
  clienteDestSdi: string;
}

export class ItemCarrello {
  dtOraInsert: number;
  dtOraUpdate: number;
  fkIdApplicazione: number;
  fkIdCarrello: number;
  fkIdEnte: number;
  idItemCarrello: number;
  idPagamento: number;
  causale: string;
  causaleRaggruppamento: string;
  codiceEnte: string;
  codiceIva: string;
  codiceRaggruppamento: string;
  importoUnitario: number;
  quantitaItem: number;
  importo: number;
  importoTotale: number | string;
  progressivoRaggruppamento: number;
  tipoContabilizzazione: string;
  importoImposta: number;
  
  aliquotaIva: string;
  pagamentoSpontaneo: string;
}

export class Pagante {
  paganteCap: string;
  paganteCfPiva: string;
  pagantePiva: string;
  paganteCivico: string;
  paganteComune: string;
  paganteDenominazione: string;
  paganteNome: string;
  paganteEmail: string;
  paganteIndirizzo: string;
  paganteSglPrv: string;
}

export class Cliente {
  clienteCap: string;
  clienteCfPiva: string;
  clientePiva: string;
  clienteCivico: string;
  clienteComune: string;
  clienteDenominazione: string;
  clienteNome: string;
  clienteEmail: string;
  clienteIndirizzo: string;
  clienteSglPrv: string;
  clienteDestSdi: string;
  clientePecSdi: string;

  tipoContabilizzazione: string;
}
