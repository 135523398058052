<div class="card mb-4" *ngIf="!visualizzaGrigliaCausali">
    <div class="card-body" [ngModelGroup]="nameModelGroup">
        <div *ngIf="entiEnabled">
            <div class="d-none form-group" [class.cross-validation-error]="hasError('ente')">
                <label [for]="nameModelGroup + 'Ente'">{{
          "INFO_PAGAMENTO.ENTE" | translate
          }}</label>
                <select hidden placeholder="Ente" class="form-control"
                    [id]="nameModelGroup + 'Ente'" [ngModel]="anagrafica.codiceEnte"
                    (ngModelChange)="onSelectEnte($event)" name="ente" required>
          <option
            *ngFor="let ente of entiEnabled; let i = index"
            [value]="ente.codiceEnte"
          >
            {{ ente.codiceEnte }}
          </option>
          <!-- <option *ngFor="let ente of entiEnabled; let i=index" [value]="ente.codiceEnte">{{ente.denominazioneEnte?ente.codiceEnte + " - " + ente.denominazioneEnte:ente.codiceEnte + " - denominazione da fornire"}}</option> -->
          <!-- [selected]="i === 0" -->
         </select>
            </div>
            <div class="row">
                <div class="form-group col-sm-6" [class.cross-validation-error]="hasError('servizio')">
                    <input type="text" class="form-control" [id]="nameModelGroup + 'Ente1'" [(ngModel)]="anagrafica.enteDesc" name="enteDesc" [readonly]="true" />
                </div>
            </div>

            <!-- [ngModelGroup]="nameModelGroup"-->
            <div class="row">
                <!--   [class.cross-validation-error]="hasError('servizio')"-->
                <div class="form-group col-sm-6" [class.cross-validation-error]="hasError('servizio')">
                    <label [for]="nameModelGroup + 'Servizio'">{{ "INFO_PAGAMENTO.SERVIZIO" | translate }} *</label>
                    <select [ngModel]="anagrafica.idServizio" (ngModelChange)="onSelectApplicazione($event)" [id]="nameModelGroup + 'Servizio'" placeholder="Elenco dei servizi / Applicazioni dell'ente" class="form-control" name="servizio" required>
<option
*ngFor="let applicazione of applicazioniEnabled"
[value]="applicazione.idApplicazione"
>
<!--Internazionalizzazione formato campo it:valore italiano # de:valore tedesco-->
<div *ngIf="translate.currentLang == 'it'">
  {{
    applicazione.denominazioneApp.split("#")[0].split(":")[1] !=
    null
      ? applicazione.denominazioneApp.split("#")[0].split(":")[1]
      : applicazione.denominazioneApp
  }}
</div>
<div *ngIf="translate.currentLang == 'de'">
  {{
    applicazione.denominazioneApp.split("#")[1] != null
      ? applicazione.denominazioneApp.split("#")[1].split(":")[1]
      : applicazione.denominazioneApp
  }}
</div>
</option>
</select>
                </div>
            </div>

            <!--CAUSALE ATTUAL -->
            <div class="form-row">
                <div class="form-group col-sm-12" [class.cross-validation-error]="hasError('causale')">
                    <label [for]="nameModelGroup + 'Causale'">{{ "INFO_PAGAMENTO.CAUSALE" | translate }} *</label
          >
          <textarea
            [(ngModel)]="anagrafica.causale"
            [id]="nameModelGroup + 'Causale'"
            name="causale"
            class="form-control"
            maxlength="120"
            rows="3"
            ng-pattern="/^@[a-zA-Z0-9]+(?:,\s*@[a-zA-Z0-9]+)*$/"
            required
            placeholder="{{ 'INFO_PAGAMENTO.PLACEHOLDER' | translate }}"
          ></textarea>
         </div>
      </div>
      <!--FINE CAUSALE ATTUALE -->

    </div>
  </div>
