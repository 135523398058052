import { stringify } from '@angular/compiler/src/util';
import { Input, Component, AfterContentChecked } from '@angular/core';
import { NgForm, FormGroup, FormControl } from '@angular/forms';
import { faGamepad } from '@fortawesome/free-solid-svg-icons';

@Component({
  template: ''
})
export abstract class FormComponent implements AfterContentChecked {

  abstract ngAfterContentChecked(): void;

  @Input() form: NgForm;
  @Input() nameModelGroup: string;
  @Input() isDetail = false;

  hasError(nameControl: string): boolean {
    return (this.form.controls[this.nameModelGroup] as FormGroup)?.controls[nameControl]?.invalid;
  }

  checkNumeroPositivo(nameControl: string): boolean {
    return ((this.form.controls[this.nameModelGroup] as FormGroup)?.controls[nameControl]?.value <= 0
           ||
           (this.form.controls[this.nameModelGroup] as FormGroup)?.controls[nameControl]?.invalid);
  }

  disableControls(): void {
    return (this.form.controls[this.nameModelGroup] as FormGroup)?.disable();
  }

  disableControl(nameControl: string): void {
    return (this.form.controls[this.nameModelGroup] as FormGroup)?.controls[nameControl]?.disable();
  }

  enableControl(nameControl: string): void {
    return (this.form.controls[this.nameModelGroup] as FormGroup)?.controls[nameControl]?.enable();
  }
}
