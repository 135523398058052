import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { Utility } from 'src/app/_shared/utility';

export function codiceFiscaleOrPIvaValidator(checkestero: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value: string = control.value;

    if (!checkestero) {
      if (Utility.isStringNullOrUndefinedOrEmpty(value)
        || (value.length === 11 && /^\d+$/.test(value))) { return null; }

      if (Utility.isStringNullOrUndefinedOrEmpty(value)
        || value.length == 16 && (/[a-zA-Z0-9._]{1,16}/.test(value))) { return null; }

      return { invalidCodiceFiscaleOrPIva: 'Codice fiscale o partita iva non valida' };
    }
    else {
      if (!Utility.isStringNullOrUndefinedOrEmpty(value)
        // || value.length >= 16
        && (/[a-zA-Z0-9._]{1,35}/.test(value)) )
        { return null; }

      return { invalidCodiceFiscaleOrPIva: 'Codice fiscale o partita iva non valida' };
    }
  };
}

@Directive({
  selector: '[appCodiceFiscaleOrPIva]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CodiceFiscaleOrPIvaDirective, multi: true }]
})
export class CodiceFiscaleOrPIvaDirective implements Validator {
  @Input() appCodiceFiscaleOrPIva: boolean;

  validate(control: AbstractControl): ValidationErrors {
    return codiceFiscaleOrPIvaValidator(this.appCodiceFiscaleOrPIva)(control);
  }
  registerOnValidatorChange?(fn: () => void): void { }
}
