import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PaganteStorageService } from 'src/app/_shared/models/pagante-storage-service';

@Component({
  selector: 'app-modal-check-error',
  templateUrl: './modal-check-error.component.html',
  styleUrls: ['./modal-check-error.component.css']
})
export class ModalCheckErrorComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private storageService: PaganteStorageService
    ) { }
  @ViewChild('content') content;

  limiteErrori:boolean=false;
  errorePagamentoSpontaneoNgIf:boolean;
  erroreImportoTotaleNgIf:boolean;
  ngOnInit(): void {



  }

  open(): void {
    //gestione messaggio servizi oltre 5=============
    let oltreCinque= localStorage.getItem('oltreCinque');

    let erroreTotale= this.storageService.getTotale();
    if(erroreTotale=="false"){
      this.erroreImportoTotaleNgIf=false;//non mostra il messaggio
    }else {
        this.erroreImportoTotaleNgIf=true;//mosta il messaggio
      }

      console.log("ERRORE TOTALE",   erroreTotale);


    let errorePsFromStorage=this.storageService.getErrorePagamentoSpontaneo();
    if(errorePsFromStorage=="false"){
      this.errorePagamentoSpontaneoNgIf=true;
    }else{
      this.errorePagamentoSpontaneoNgIf=false;
    }

    if(oltreCinque=="0"){
      console.log("oltrecinqu zero", oltreCinque);
     this.limiteErrori=false;

    }else{
     this.limiteErrori=true;
     console.log("oltrecinqu uno", oltreCinque);
     oltreCinque="0";
    }
    console.log("OLTRE CINQUE", oltreCinque);
  //fine gestione messaggio=========================
    this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }


}
