export class DettagliService{
private serviceChoices;
private serviceListaDettagli
private serviceSeSipa

setChoices(arr){
arr=this.serviceChoices;
}

getChoices(){
    return this.serviceChoices;
}


setListaDettagli(listaDettagli){
    listaDettagli=this.serviceListaDettagli;
}
getListaDettagli(){
    return this.serviceListaDettagli;
}

setServiceSeSipa(seSipa){
seSipa=this.serviceSeSipa
}

getServiceSeSipa(){
    return this.serviceSeSipa;
}

}


