import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthStorageService } from 'src/app/_shared/auth-storage.service';
import { ConfigService } from 'src/app/_shared/config.service';
import { PagamentoService } from 'src/app/_shared/services/pagamento.service';

@Component({
  selector: 'app-esito',
  templateUrl: './esito.component.html',
  styleUrls: ['./esito.component.css']
})
export class EsitoComponent implements OnInit, AfterViewInit {

  @Input() idCarrelloSorgente: any;
  @Input() listIdPagamentoTas: any;
  @Input() statoPagamento: any;
  @Input() isEmail = false;

  descrizioneEsito: string;
  isOK: boolean;
  caricamentoDaMail: boolean;
  lingua: string;

  @BlockUI() blockUI: NgBlockUI;

  constructor(private translate: TranslateService,
    private configFile: ConfigService,
    private router: Router,
    private storage: AuthStorageService,
    private pagamentoService: PagamentoService) { }


  ngAfterViewInit(): void {
    //this.translate.resetLang(this.translate.currentLang);
    if (!this.translate.currentLang) {
      this.translate.reloadLang(this.translate.currentLang);
    }
  }

  ngOnInit(): void {

    if (this.statoPagamento === StatoEsitoPagamento.AVVENUTO.valueOf()
      || this.statoPagamento === StatoEsitoPagamento.CONCLUSO.valueOf()
      || this.statoPagamento === StatoEsitoPagamento.VERIFICATO.valueOf()) {
      this.isOK = true;
    }
    else if (this.statoPagamento === StatoEsitoPagamento.ANNULLATO.valueOf()
      || this.statoPagamento === StatoEsitoPagamento.ERRORE.valueOf()
      //|| this.statoPagamento === StatoEsitoPagamento.SXX.valueOf()
      || this.statoPagamento === StatoEsitoPagamento.UNKNOWN.valueOf()) {
      this.isOK = false;
    }


    this.lingua = this.storage.getLingua();
    this.translate.langs = this.configFile.getConfig().langs;
    this.translate.setDefaultLang(this.lingua);
    this.translate.use(this.lingua);

    this.setDescrizioneEsito();
    this.AggiornaStatoPagamento();
  }

  private setDescrizioneEsito(): void {
    let esito = null;

    switch (this.statoPagamento) {
      case StatoEsitoPagamento.AVVENUTO.valueOf(): {
        esito = StatoEsitoPagamento.AVVENUTO;
        break;
      }
      case StatoEsitoPagamento.ANNULLATO.valueOf(): {
        esito = StatoEsitoPagamento.ANNULLATO;
        break;
      }
      case StatoEsitoPagamento.CONCLUSO.valueOf(): {
        esito = StatoEsitoPagamento.CONCLUSO;
        break;
      }
      case StatoEsitoPagamento.ERRORE.valueOf(): {
        esito = StatoEsitoPagamento.ERRORE;
        break;
      }
      // case StatoEsitoPagamento.SXX.valueOf(): {
      //   esito = StatoEsitoPagamento.SXX;
      //   break;
      // }
      case StatoEsitoPagamento.VERIFICATO.valueOf(): {
        esito = StatoEsitoPagamento.VERIFICATO;
        break;
      }
      default: {
        esito = StatoEsitoPagamento.UNKNOWN;
        break;
      }
    }
    //Sostituito con il translate
    //this.descrizioneEsito = DescrizioneEsitoPagamento[esito];
    this.descrizioneEsito = "ERRORE." + esito;
  }

  nuovoPagamento(): void {
    //Chiamata con parametri
    // this.router.navigate(['NuovoPagamento',
    // {codiceEnte: this.storage.getCodiceEnte(), lingua: this.translate.defaultLang}
    // ]);

    this.router.navigate(['NuovoPagamento']);
  }

  toggleBlocking(message?: string) {
    var attendere = ""; //this.translate.instant("BUTTON.ATTENDERE");
    this.blockUI.start(attendere);

    setTimeout(() => {
      this.blockUI.stop();
    }, 2000);
  }

  AggiornaStatoPagamento() {
    this.pagamentoService.aggiornaStatoPagamento(this.statoPagamento, this.idCarrelloSorgente).subscribe(
      (esito) => { console.log("esito updatePagamento " + esito.codiceEsito); }
    );
  }

}

export enum StatoEsitoPagamento {
  AVVENUTO = 'VV',
  ANNULLATO = 'NN',
  CONCLUSO = 'CO',
  VERIFICATO = 'ER',
  ERRORE = 'XX',
  //SXX = 'SXX',
  UNKNOWN = 'ALTRO'
}

// export enum DescrizioneEsitoPagamento {
//   VV = 'Pagamento avvenuto con successo',
//   NN = 'Operazione annullata',
//   CO = 'Pagamento concluso',
//   ER = 'Pagamento verificato correttamente',
//   XX = 'Errore nel processo di pagamento',
//   //SXX = 'Errore nel processo di pagamento',
// //  SXX = 'Ops.. qualcosa è andato storto.. è necessario ripetere la procedura di pagamento',
//   UNKNOWN = 'Esito non previsto'
// }
