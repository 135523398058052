import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit,
  Input,
} from '@angular/core';
import { NgWizardService } from 'ng-wizard';
import { Anagrafica } from 'src/app/_shared/models/anagrafica';
import { NgForm } from '@angular/forms';
import { ModalCheckErrorComponent } from 'src/app/modals/modal-check-error/modal-check-error.component';
import { FormDataAnagraficaStorage } from 'src/app/_shared/services/form-data.service';
import { EventEmitterService } from 'src/app/_shared/event-emitter-service';
import { PaganteStorageService } from 'src/app/_shared/models/pagante-storage-service';
import { EntiService } from 'src/app/_shared/services/enti.service';
@Component({
  selector: 'app-anagrafica',
  templateUrl: './anagrafica.component.html',
  styleUrls: ['./anagrafica.component.css'],
})
export class AnagraficaComponent implements OnInit, AfterViewInit {
  @Output() servizioAna = new EventEmitter<boolean>();

  // public visibility: boolean = false;
  public tipoServizio: string;
  // public validi = "|TA|TS|UN|";
  public storeCheckEstero: boolean;
  disableLang: boolean;
  visualizzaImportoSeSipaBase: boolean = false;
  //ricevutoPlus: any;
  //sePlus: String;


  constructor(
    private formStorage: FormDataAnagraficaStorage,
    private ngWizardService: NgWizardService,
    private eventEmitterService: EventEmitterService,
    private storagePagamento: PaganteStorageService,
    private entiSrv: EntiService
  ) { }

  ngAfterViewInit(): void { }

  @ViewChild(ModalCheckErrorComponent) modalCheckError: ModalCheckErrorComponent;
  @ViewChild('anagraficaForm') anagraficaForm: NgForm;
  anagrafica: Anagrafica;

  @Output() callHeader = new EventEmitter();
  @Input() paganteNonCoincide = false; //A.A.

  campiObbligatori: boolean = false; //A.A. condizione per rendere obbligatori i campi sdi 
  isDisabled: boolean = false; //A.A. nuova variabile per disabilitare il ceckbox Fatturazione

  ngOnInit(): void {
    this.anagrafica = this.formStorage.data;
    if (this.storagePagamento.getCodiceFiscale()) {
      this.settaPagante();
      this.storagePagamento.clear();
    }

    if (this.anagrafica.cliente.codiceFiscale) {
      this.paganteNonCoincide = true;
    }
  }

  /*
   * Visualizzo pecSdi se tipoContabilizazzione in |TA|TS|UN|
   * @param servizio
   */
  // onChangeServizio(servizio) {
  //   this.tipoServizio = servizio;
  //   if (this.validi.includes('|' + servizio + '|')) {
  //     this.visibility = true;
  //     //Visualizzo pecSdi
  //     this.storage.setVisibility("true");
  //   }
  //   else {
  //     this.visibility = false;
  //     //Non visualizzo pecSdi
  //     this.storage.setVisibility("false");
  //     this.anagrafica.cliente = null
  //   }
  // }

  // onChangeServizio(servizio) {
  //    this.anagrafica.cliente = null
  // }


  metodoSePlus(ev) {
    ev == '0' ? (this.visualizzaImportoSeSipaBase = true) : (this.visualizzaImportoSeSipaBase = false);
  }

  seCinque: any;
  seCinqueInvia: any;
  piuDiCinqueSel(eve) {
    this.seCinque = eve;
    this.seCinqueInvia = this.seCinque;
    //console.log(this.seCinqueInvia);
  }

  EventCheckEstero(event) {
    //console.log('event check', event);
    this.storeCheckEstero = event;
  }

  showNextStep(event?: Event): void {
    this.eventEmitterService.subsVarServizio = undefined;
    this.disableLang = true;
    this.ngWizardService.next();
  }

  showPreviousStep(event?: Event): void {
    this.disableLang = false;
    this.ngWizardService.previous();
  }

controlloPagamentoSpontaneo:boolean;
seVuotoPagamentoSpontaneo(e)  {
  //console.log("controllo pagamento spontane E =", e)
    this.controlloPagamentoSpontaneo= e;
 }
 controlloTotaleSeVuoto:boolean;
seVuotoTotale(e){
  this.controlloTotaleSeVuoto=e;
}
  // ---------------
  servizio: any;
  takeService(ev) {
    this.servizio = ev;
  }
  // --------------
  onSubmit(anagraficaForm: NgForm): void {
    //console.log('dentro submit', this.servizio);
    //se selezionati piu di ciunque servizi oppure se pagamento spontaneo e' vuoto mostra errore
if (anagraficaForm.invalid || this.servizio > 5 || this.controlloPagamentoSpontaneo==false || this.controlloTotaleSeVuoto==true ) {
      this.modalCheckError.open();
      this.servizio = 0;
    } else {
      this.formStorage.load(this.anagrafica);
      this.servizio = 0;
      this.showNextStep();
    }
  }
  leggeReturnRighe(e) {
    this.clearTabella = e;
  }

  clearTabella: boolean = true;
  clearForm(anagraficaForm: NgForm): void {
    anagraficaForm.reset();
    this.formStorage.reset();
    this.anagrafica = this.formStorage.data;

    this.clearTabella = false;
  }

  //A.A. se i campi aliquotaIva sono maggiori di 0 e apro anagrafica-persona 
  aliquotaMaggioreZero(e) {
    this.paganteNonCoincide = e;
    this.campiObbligatori = e;
    this.updateCheckboxState(); // Disabilita il checkbox Fatturazione
  }
  
   //A.A. se i campi aliquotaIva sono maggiori di 0 campi SDI obligatori e ceckbox si disabilita
   checkCampiObbligatorio() {
    this.campiObbligatori = !this.campiObbligatori;
    this.updateCheckboxState(); 
  }

  checkPaganteClick() {
    if (!this.isDisabled) {
    if (!this.paganteNonCoincide) {
      if (this.storeCheckEstero) {
        this.anagrafica.cliente.checkEstero = true;
      } else {
        this.anagrafica.cliente.provincia = this.anagrafica.pagante.provincia;
      }
      this.anagrafica.cliente.provincia = this.anagrafica.pagante.provincia;
      this.anagrafica.cliente.nome = this.anagrafica.pagante.nome;
      this.anagrafica.cliente.cap = this.anagrafica.pagante.cap;
      this.anagrafica.cliente.codiceFiscale = this.anagrafica.pagante.codiceFiscale;
      this.anagrafica.cliente.partitaIva = this.anagrafica.pagante.partitaIva;
      this.anagrafica.cliente.cognome = this.anagrafica.pagante.cognome;
      this.anagrafica.cliente.civico = this.anagrafica.pagante.civico;
      this.anagrafica.cliente.indirizzo = this.anagrafica.pagante.indirizzo;
      this.anagrafica.cliente.email = this.anagrafica.pagante.email;
      this.anagrafica.cliente.localita = this.anagrafica.pagante.localita;
      this.anagrafica.cliente.siglaProv = this.anagrafica.pagante.siglaProv;
    } else {
      this.anagraficaForm.form.get('cliente').reset();
    }
   }
  }

  settaPagante() {
    this.anagrafica.pagante.nome = this.storagePagamento.getNome();
    this.anagrafica.pagante.cap = this.storagePagamento.getCap();
    this.anagrafica.pagante.checkEstero = this.storagePagamento.getCheck() == 'true';
    this.anagrafica.pagante.provincia = this.storagePagamento.getProvincia();
    this.anagrafica.pagante.codiceFiscale = this.storagePagamento.getCodiceFiscale();
    this.anagrafica.pagante.partitaIva = this.storagePagamento.getPartitaIva();
    this.anagrafica.pagante.cognome = this.storagePagamento.getCognome();
    this.anagrafica.pagante.civico = this.storagePagamento.getCivico();
    this.anagrafica.pagante.indirizzo = this.storagePagamento.getIndirizzo();
    this.anagrafica.pagante.email = this.storagePagamento.getMail();
    this.anagrafica.pagante.localita = this.storagePagamento.getComune();
    this.anagrafica.pagante.siglaProv = this.storagePagamento.getSiglaProv();

  }

  private updateCheckboxState() {
    this.isDisabled = this.paganteNonCoincide || this.campiObbligatori; //A.A. Controlla lo stato di isDisabled prima di eseguire altre azioni
  }

  copiaValoriClientePagante() {    //A.A. copia i valori da cliente a pagante quando le applicazioni prevedeno aliquota Iva
  if (this.anagrafica && this.anagrafica.pagante) {
    this.anagrafica.cliente.nome = this.anagrafica.pagante.nome;
    this.anagrafica.cliente.cognome = this.anagrafica.pagante.cognome;
    this.anagrafica.cliente.codiceFiscale = this.anagrafica.pagante.codiceFiscale;
    this.anagrafica.cliente.partitaIva = this.anagrafica.pagante.partitaIva;
    this.anagrafica.cliente.indirizzo = this.anagrafica.pagante.indirizzo;
    this.anagrafica.cliente.civico = this.anagrafica.pagante.civico;
    this.anagrafica.cliente.cap = this.anagrafica.pagante.cap;
    this.anagrafica.cliente.localita = this.anagrafica.pagante.localita;
    this.anagrafica.cliente.provincia = this.anagrafica.pagante.provincia;
    this.anagrafica.cliente.email = this.anagrafica.pagante.email;
    this.anagrafica.cliente.siglaProv = this.anagrafica.pagante.siglaProv;
  }
}
}
