<div class="card mb-4">
    <!--*ngIf="selezionaServizi"-->

    <div class="card-body">

        <div name="tabellaDettagliRiepilogo">
            <!--*ngIf="!mostraTabella"-->
            <div class="form-group">
                <div class="table-responsive">
                    <table class="table">
                        <tr class="wrapper2">
                            <th class="box-intestazione" style="display:none"></th>
                            <th class="box-intestazione">{{ 'SERVIZI.CAUSALE_DETTAGLIO' | translate }}</th>
                            <th class="box-intestazione">{{ 'SERVIZI.IMPORTO_NETTO' | translate }}</th>
                            <th class="box-intestazione">{{ 'SERVIZI.IMPORTO_IMPOSTA' | translate }}</th>
                            <th class="box-intestazione">{{ 'SERVIZI.QUANTITA' | translate }}</th>
                            <th class="box-intestazione">{{ 'SERVIZI.TOTALE' | translate }}</th>
                            <th class="box-intestazione" style="display:none">Tipo contabilizzazione</th>
                        </tr>

                        <div *ngFor="let dettaglio of listaCausaliMostrate">

                            <tr class="wrapper2">
                                <!-- <td class="box" style="display:none">
                                    <input *ngIf="checkValore" id="{{ 'checkbox-' + dettaglio.idCausale }}" type="checkbox" name="checkbox" [value]="dettaglio.idCausale" />
                                </td> -->
                                <!-- dettaglio-->
                                <td class="box">
                                    <input id="{{ 'idDettaglio-' + dettaglio.idCausale }}" value="{{ dettaglio.descCausale }}" disabled />
                                </td>
                                <!-- importo netto-->
                                <td class="box">
                                    <input id="{{ 'idimportoNetto-' + dettaglio.idCausale }}" value="{{ dettaglio.importoUnitario | number:'0.2' }}" disabled />
                                </td>
                                <!-- importo iva-->
                                <td class="box">
                                    <input id="{{ 'idAliquotaIva-' + dettaglio.idCausale }}" value="{{ dettaglio.importoImposta | number:'0.2' }}" disabled />
                                </td>
                                <!-- quantità -->
                                <td class="box">
                                    <input type="number" id="{{ 'inputQuantita-' + dettaglio.idCausale }}" name="inputQuantita" class="inputQuantita" [value]="dettaglio.quantita"
                                        disabled />
                                </td>
                                <td class="box">
                                    <input type="text" id="{{ 'inputTotale-' + dettaglio.idCausale }}" name="inputTotale" class="inputTotaleRiga" value="{{ dettaglio.importo | number:'0.2' }}"
                                        disabled />
                                </td>
                            </tr>
                            <!--tipo contabilizzazione-->
                            <td class="box" style="display:none">
                                {{dettaglio.codiceTipoContabilizzazione}}
                            </td>
                        </div>

                        <div class="wrapper2">
                            <div class="box-totale" style="display:none"></div>
                            <div class="box-totale"></div>
                            <div class="box-totale"></div>
                            <div class="box-totale"></div>
                            <div class="box-totale" id="importo">Importo:</div>
                            <div class="box" id="cellaTotale">{{ importoTotale | number:'0.2'}}</div>
                        </div>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
