<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title"> {{ 'MESSAGE_ERROR.ATTENZIONE' | translate }}!</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        {{ 'MESSAGE_ERROR.CAMPI_OBBLIGATORI' | translate }}<br/><br/> {{ 'MESSAGE_ERROR.CAMPI_DA_CORREGGERE' | translate }}
    </div>

    <div class="modal-body" *ngIf="errorePagamentoSpontaneoNgIf">
        {{ 'MESSAGE_ERROR.IMPORTO_PAGAMENTOSPONTANEO' | translate }}

    </div>
    <div class="modal-body" *ngIf="erroreImportoTotaleNgIf">
        {{ 'MESSAGE_ERROR.IMPORTO_TOTALE' | translate }}<br/><br/>
    </div>

    <div class="modal-body" *ngIf="limiteErrori">
        {{ 'MESSAGE_ERROR.LIMITE_SERVIZI' | translate }}<br/><br/>

    </div>
    <div class="modal-footer" style="border-top: none;">
        <button type="button" class="btn btn-info" (click)="modal.close('Save click')"> {{ 'BUTTON.OK' | translate }}</button>
    </div>
</ng-template>
