import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgWizardService } from "ng-wizard";
import { Anagrafica } from "src/app/_shared/models/anagrafica";
import { NgForm } from "@angular/forms";
import { FormDataAnagraficaStorage } from "src/app/_shared/services/form-data.service";
import { PagamentoService } from "src/app/_shared/services/pagamento.service";
import { Utility } from "src/app/_shared/utility";
import { ConfigService } from "../../../_shared/config.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { AuthStorageService } from "src/app/_shared/auth-storage.service";
import { PaganteStorageService } from "src/app/_shared/models/pagante-storage-service";
import { CausaleDto } from "src/app/_shared/models/ente";

@Component({
  //Toglie errore translate nel passaggio della lingua dalla home page
  // changeDetection: ChangeDetectionStrategy.Default,
  selector: "app-pagamento",
  templateUrl: "./pagamento.component.html",
  styleUrls: ["./pagamento.component.css"],
})
export class PagamentoComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  anagrafica: Anagrafica;

  @Input() tipoServizio: boolean;

  @ViewChild("pagamentoForm") pagamentoForm: NgForm;
  paganteNonCoincide = false;
  causaleServizio: boolean = false;
  visualizzaImporto: boolean = true;
  seSipa: any;

  // visibility: boolean;

  constructor(
    private ngWizardService: NgWizardService,
    private formDataStorage: FormDataAnagraficaStorage,
    private pagamentoService: PagamentoService,
    private configFile: ConfigService,
    private storage: AuthStorageService,
    private storagePagamento: PaganteStorageService
  ) {
    window.scroll(0, 0);
  }
  arrSelezionati;
  ngOnInit(): void {
    this.anagrafica = this.formDataStorage.data; //new
    if (this.anagrafica.cliente.codiceFiscale) {
      this.paganteNonCoincide = true;
    }
    //console.log("STAMAPA PAGAMENTO", this.anagrafica);

    // this.visibility= this.storage.getVisibility()=="true";
    this.arrSelezionati = JSON.parse(localStorage.getItem("choicesSelezionati"));

    //console.log("SELEZIONATI", this.arrSelezionati);
  }

  toggleBlocking(message?: string) {
    var attendere = ""; //this.translate.instant("BUTTON.ATTENDERE");
    this.blockUI.start(attendere);

    setTimeout(() => {
      this.blockUI.stop();
    }, 2000);
  }

  showNextStep(event?: Event): void {
    this.ngWizardService.next();
  }

  showPreviousStep(event?: Event): void {
    this.ngWizardService.previous();
  }

  leggeReturnRighe(e) {
    this.clearTabella = e;
  }

  clearTabella: boolean = true;


  confermaPagamento(): void {
    let tmp: Array<CausaleDto> = [];
//debugger;
    if (this.seSipa == 1) {
      this.arrSelezionati.forEach((item) => {
        let c = new CausaleDto();
        c.causaleRaggruppamento = this.anagrafica.causale;
        c.codiceEnte = this.anagrafica.codiceEnte;
        c.codiceTipoContabilizzazione = item.codiceTipoContabilizzazione;
        c.descCausale = item.descCausale;
        c.fSpontaneo = item.fSpontaneo;
        c.importo = item.importo;
        if (!Utility.isStringNullOrUndefinedOrEmpty(item.aliquota))
          c.aliquota = item.aliquota;
        c.importoUnitario = item.importo;
        c.quantita = item.quantita;
        c.totale = item.totale;
        c.idCausale = item.idCausale;
        c.fObbligatorio=item.fObbligatorio;
        tmp.push(c);
      });
    }
    else
    {
      let c = new CausaleDto();
      c.causaleRaggruppamento = this.anagrafica.causale;
      c.codiceEnte = this.anagrafica.codiceEnte;
      c.codiceTipoContabilizzazione = this.anagrafica.tipoContabilizzazione;
      c.descCausale = this.anagrafica.causale;
      c.fSpontaneo = "0";
      c.fObbligatorio = 0;
      c.importo = "" + this.anagrafica.importo;
      c.aliquota = "";
      c.importoUnitario = this.anagrafica.importo;
      c.quantita = "1"
      c.totale = "" + this.anagrafica.importo;
      c.idCausale = null;
      tmp.push (c);
    }

    this.anagrafica.listaCausali = tmp.slice();

    // arrSelezionati
    //-----------------------------
    //  aliquota: null
    //  codiceCausale: null
    //  codiceTipoContabilizzazione: "DI"
    //  descCausale: "Sala verde"
    //  fSpontaneo: "1"
    //  idCausale: "1120"
    //  importo: ""
    //  quantita: ""
    //  totale: "25.00"
    //  totaleFix: ""
    //-----------------------------
    // aliquota: "22"
    // codiceCausale: null
    // codiceTipoContabilizzazione: "TS"
    // descCausale: "Sala celeste"
    // fSpontaneo: "0"
    // idCausale: "1100"
    // importo: "99"
    // quantita: "1"
    // totale: 120.78
    //-----------------------------


    this.storagePagamento.setCognome(this.anagrafica.pagante.cognome);
    this.storagePagamento.setNome(this.anagrafica.pagante.nome);
    this.storagePagamento.setCap(this.anagrafica.pagante.cap);
    if (this.anagrafica.pagante.provincia == "EE")
      this.storagePagamento.setCheck("true");
    else
      this.storagePagamento.setCheck("false");
    this.storagePagamento.setCivico(this.anagrafica.pagante.civico);
    this.storagePagamento.setCodiceFiscale(this.anagrafica.pagante.codiceFiscale);
    if (!Utility.isStringNullOrUndefinedOrEmpty(this.anagrafica.pagante.partitaIva))
      this.storagePagamento.setPartitaIva(this.anagrafica.pagante.partitaIva);
    this.storagePagamento.setComune(this.anagrafica.pagante.localita);
    this.storagePagamento.setIndirizzo(this.anagrafica.pagante.indirizzo);
    this.storagePagamento.setProvincia(this.anagrafica.pagante.provincia);
    this.storagePagamento.setMail(this.anagrafica.pagante.email);
    this.storagePagamento.setSiglaProv(this.anagrafica.pagante.siglaProv);

    if (this.pagamentoForm.invalid) {
    }
    else
    {
      console.log("this.anagrafica >" + this.anagrafica);
      this.pagamentoService.pagaCarrello(this.anagrafica).subscribe(
        (response) => {
         // debugger;
          if (!Utility.isStringNullOrUndefinedOrEmpty(response.urlPga))
          {
            window.location.href = response.urlPga;
          }
          else
          {
            const baseSito = this.configFile.getConfig().BaseUrlSito; // Config.BaseUrlSito;
            window.location.href =
              baseSito +
              "/Pagamento/Esito?idCarrelloSorgente=KO&listIdPagamentoTas=XX&statoPagamento=XX";
          }
        },
        (error) => console.error(error)
      );
    }
  }


  //gestisce visualizzazione importo nel riepilogo dati inseriti
  metodoSePlus(ev) {
    this.seSipa = ev;
    if (this.seSipa == "1") {
      this.visualizzaImporto = false;
      this.causaleServizio = false
    } else if (this.seSipa == "0") {
      this.visualizzaImporto = true;
      this.causaleServizio = true
    }
  }
}
