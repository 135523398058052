<form class="mt-4 mb-4">
  <!-- PAGAMENTO OK -->
  <div class="card mb-4" *ngIf="this.isOK === true">
    <div class="card-body">
      <div class="form-row">
        <div class="form-group col-sm-12">
            <h4>{{ 'ESITO.PAGAMENTO_OK' | translate}}</h4>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <Label class="label">{{ 'ESITO.ID_PAGAMENTO' | translate}}:</Label>
          <Label>{{this.listIdPagamentoTas}}</Label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <Label class="label">{{ 'ESITO.TITLE' | translate}}:</Label>
          <Label>{{this.descrizioneEsito | translate}}</Label>
        </div>
      </div>
      <div class="form-row" *ngIf="!this.isEmail">
        <div class="form-group col-sm-12">
          <Label>
             {{ 'ESITO.MAIL' | translate}}
          </Label>
        </div>
      </div>
    </div>
  </div>

  <!-- ERRORE-->
  <div class="card mb-4" *ngIf="this.isOK === false">
    <div class="card-body">
      <div class="form-row">
        <div class="form-group col-sm-12">
            <h4>{{ 'ESITO.PAGAMENTO_KO' | translate}}</h4>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <Label class="label">{{'ESITO.CODICE_ERRORE' | translate}}:</Label>
          <Label>{{this.statoPagamento | translate}}</Label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <Label class="label">{{'ESITO.CODICE_ESITO' | translate}}:</Label>
          <Label>{{this.descrizioneEsito | translate}}</Label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <Label>
             {{ 'ESITO.RIPROVA' | translate}}
          </Label>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="!this.isEmail" fdiv class="form-group col-sm-12 mt-4">
    <div class="text-center">
      <button type="button" class="btn btn-success ml-2" (click)="toggleBlocking('Attendere, prego...'); nuovoPagamento();">
        {{ 'BUTTON.NUOVO_PAGAMENTO' | translate}}
      </button>
    </div>
  </div>

</form>
