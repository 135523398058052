import { Persona } from './Persona';
import { CarrelloResponse } from './dtos/carrello-response';
import { CausaleDto } from './ente';
import { SipaPlusStorageService } from '../sipaplus-storage.service';
import { Utility } from '../utility';

export class Anagrafica {
  codiceEnte: string;
  servizio: string;
  idServizio: number;
  cdp: string;
  cpk: string;
  tipoContabilizzazione: string; //ABA Attenzione vale solo per SIPA base
  causale: string;
  causaleRaggruppamento: string;
  codiceDestinatario: string;
  pecSDI: string;
  quantitaItem: number; //ABA Attenzione vale solo per SIPA base
  importo: number; //ABA Attenzione vale solo per SIPA base
  listaCausali: CausaleDto[];
  sipaPlus: string;


  //ABA Attenzione vale solo per SIPA base
  get importoTotale(): number | string {
    if (this.importo && this.quantitaItem) {
      return this.importo * this.quantitaItem;
    } else {
      return '';
    }
  }

  //aggiungere tutti i nuovi dati per il carrello dettaglio
  pagante: Persona = new Persona();
  cliente: Persona = new Persona();
  applicazioneDesc: string;
  enteDesc: string;

  static CreateFromCarrelloResponse(carrelloResponse: CarrelloResponse): Anagrafica
    {
      const anagrafica = new Anagrafica();

      anagrafica.pagante.cap = carrelloResponse.sipaCarrello.paganteCap;
      anagrafica.pagante.civico = carrelloResponse.sipaCarrello.paganteCivico;
      anagrafica.pagante.codiceFiscale = carrelloResponse.sipaCarrello.paganteCfPiva;
      if (!Utility.isStringNullOrUndefinedOrEmpty(carrelloResponse.sipaCarrello.pagantePiva))
        anagrafica.pagante.partitaIva = carrelloResponse.sipaCarrello.pagantePiva;

      anagrafica.pagante.cognome = carrelloResponse.sipaCarrello.paganteDenominazione;
      anagrafica.pagante.nome = carrelloResponse.sipaCarrello.paganteNome;
      anagrafica.pagante.email = carrelloResponse.sipaCarrello.paganteEmail;
      anagrafica.pagante.indirizzo = carrelloResponse.sipaCarrello.paganteIndirizzo;
      anagrafica.pagante.provincia = carrelloResponse.sipaCarrello.paganteSglPrv;

      anagrafica.cliente.cap = carrelloResponse.sipaCarrello.clienteCap;
      anagrafica.cliente.civico = carrelloResponse.sipaCarrello.clienteCivico;
      anagrafica.cliente.codiceFiscale = carrelloResponse.sipaCarrello.clienteCfPiva;
      if (!Utility.isStringNullOrUndefinedOrEmpty(carrelloResponse.sipaCarrello.clientePiva))
        anagrafica.cliente.partitaIva = carrelloResponse.sipaCarrello.clientePiva;
      anagrafica.cliente.cognome = carrelloResponse.sipaCarrello.clienteDenominazione;
      anagrafica.cliente.nome = carrelloResponse.sipaCarrello.clienteNome;
      anagrafica.cliente.email = carrelloResponse.sipaCarrello.clienteEmail;
      anagrafica.cliente.indirizzo = carrelloResponse.sipaCarrello.clienteIndirizzo;
      anagrafica.cliente.provincia = carrelloResponse.sipaCarrello.clienteSglPrv;

      anagrafica.applicazioneDesc = carrelloResponse.sipaCarrello.applicazioneDesc;
      anagrafica.enteDesc = carrelloResponse.sipaCarrello.enteDesc;
      anagrafica.pagante.localita = carrelloResponse.sipaCarrello.paganteComune;
      anagrafica.cliente.localita = carrelloResponse.sipaCarrello.clienteComune;
      anagrafica.codiceDestinatario = carrelloResponse.sipaCarrello.clienteDestSdi;
      anagrafica.pecSDI = carrelloResponse.sipaCarrello.clientePecSdi;
      //anagrafica.codiceEnte = NON TORNA
      return anagrafica;
    }
}
