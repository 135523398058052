import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Directive } from '@angular/core';
import { Utility } from 'src/app/_shared/utility';

export function positivoValidator(): ValidatorFn {

  return (control: AbstractControl): { [key: string]: any } | null => {
    const value: string = control.value;
    if (!Utility.isStringNullOrUndefinedOrEmpty(value))
    {
      const numero: number = +value;
      if (numero > 0) {
        return null;
      }
    }
    return { positivo: 'Importo non valido' };
  };
}


@Directive({
  selector: '[positivo]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PositivoDirective, multi: true }]
})
export class PositivoDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors {
    return positivoValidator()(control);
  }
  registerOnValidatorChange?(fn: () => void): void { }
}
