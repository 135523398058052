<div class="card">
  <div class="card-body">
    <div class="form-row" [ngModelGroup]="nameModelGroup">


      <div class=" col-sm-6 col-12">
        <div class="inline">
          <div class="form-check" [class.cross-validation-error]="hasError('checkInformativa')">
            <!-- <input type="checkbox" class="form-check-input" [id]="nameModelGroup+'checkInformativa'" -->
            <input type="checkbox" class="checkBoxClass" [id]="nameModelGroup+'checkInformativa'"
              name="checkInformativa" [(ngModel)]="accettaInformativaPrivacy" required required>
            <label class="form-check-label" [for]="nameModelGroup+'checkInformativa'">{{ 'ANAGRAFICA.CHECK_PRIVACY' |
              translate }}&nbsp;&nbsp;</label>
            <a href="{{ 'ANAGRAFICA.LINK_INFORMATIVA' | translate }}" class="label" target='_blank'>(link)</a>
          </div>
        </div>
      </div>
      <div class=" col-sm-12">
        <div>
          <a *ngIf="linkInformativa2!='';" [href]="linkInformativa2" class="label" target='_blank'>{{
            'ANAGRAFICA.TERMINI_GENERALI' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
