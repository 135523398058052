<div class="card mb-4">
  <div class="card-body">
      <div class="form-row">
          <div class="col-sm-12">
              <!-- <table class="table table-striped" [ngModelGroup]="nameModelGroup">
        <thead>
          <th scope="col">Dettaglio</th>
          <th scope="col">Importo</th>
          <th scope="col">Quantità</th>
        </thead>
        <tbody>
          <tr>
            <td><label>Importo unitario</label></td>
            <td>
              <div *ngIf="!isDetail" class="col-sm-10" [class.cross-validation-error]="hasError('importoU')">
                <input type="number" class="form-control" style="text-align: right;" [id]="nameModelGroup+'ImportoU'"
                  [(ngModel)]="anagrafica.importo" name="importoU" required />
              </div>
              <div class="col-sm-10" *ngIf="isDetail">
                <label style="float: right;">{{ anagrafica.importo|currency:'€'}}</label>
              </div>
            </td>
            <td><label>{{ anagrafica.quantitaItem }}</label></td>
          </tr>
          <tr>
            <td><label>Totale</label></td>
            <td>
              <div class="col-sm-10">
                <label style="float: right;">{{ importoTotale|currency:'€'}}</label>
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table> -->


              <div class="row" [ngModelGroup]="nameModelGroup">
                  <div class="form-group col-sm-3 col-6" *ngIf="!isDetail" [class.cross-validation-error]="checkNumeroPositivo('importoU')">
                      <label [for]="nameModelGroup+'ImportoU'">{{ 'INFO_IMPORTO.IMPORTO' | translate }} *</label>
                      <input class="form-control" style="text-align: right;" (change)="checkSeparatore(nameModelGroup, $event.target.value)" [id]="nameModelGroup+'ImportoU'" placeholder="{{ 'INFO_IMPORTO.PLACEHOLDER' | translate }} " [(ngModel)]="anagrafica.importo" name="importoU"
                          type="textbox" [(ngModel)]="InputValue" appInfoImportoTwo_decimal required positivo>
                  </div>

                  <div class="form-group col-sm-6 col-12" *ngIf="isDetail">
                      <label>{{ 'INFO_IMPORTO.IMPORTO' | translate }}</label>
                      <label style="float: right;">{{anagrafica.importo|currency:'€'}}</label>
                  </div>
                  <!-- <div class="form-group col-sm-6 col-12">
          <label>Quantità</label>
          <label style="float: right;" *ngIf="isDetail">{{ anagrafica.quantitaItem }}</label>
          <div class="col-md-4" *ngIf="!isDetail"><label>{{ anagrafica.quantitaItem }}</label></div>
        </div> -->

              </div>


              <!-- <div class="row">
        <div class="form-group col-sm-6 col-12">
          <hr style="margin-top: 0;"/>
          <label>Totale</label>
          <label style="float: right;">{{ importoTotale|currency:'€'}}</label>
        </div>
      </div> -->
          </div>
      </div>
  </div>
</div>
