import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EsitoPagamentoComponent } from '../esito-pagamento/esito-pagamento.component';
import { ModalService } from '../modals/modal/modal.service';
import { AnagraficaComponent } from '../pagamento-spontaneo/wizards/anagrafica/anagrafica.component';
import { AuthStorageService } from '../_shared/auth-storage.service';
import { ConfigService } from '../_shared/config.service';
import { ConfigurazioneStyleService } from '../_shared/config/configurazione-style.service';
import { EventEmitterService } from '../_shared/event-emitter-service';
import { ConfigurazioneStyle } from './../_shared/config/configurazione-style';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent  implements OnInit{ //, AfterViewInit {

  langs : string[];;
  disableLang: boolean;

  @ViewChild(AnagraficaComponent, {static: false}) anagraficaComponent: AnagraficaComponent;
  @ViewChild(EsitoPagamentoComponent, {static: false}) esitoPagamentoComponent: EsitoPagamentoComponent;

  constructor(
    private configStyleSrv: ConfigurazioneStyleService,
    private modalService: ModalService,
    private eventEmitterService: EventEmitterService,
    private translate: TranslateService,
    private configFile: ConfigService,
    private storageSrv: AuthStorageService
    )  {

    //this.disableLang = false;
    this.translate.langs = this.configFile.getConfig().langs;
    this.translate.setDefaultLang(this.configFile.getConfig().defaultLanguage);
    this.translate.use(this.configFile.getConfig().defaultLanguage);
  }

  // ngAfterViewInit(): void {
  //   this.disableLang = this.anagraficaComponent.disableLang;
  //   console.log("disableLang " + this.disableLang);
  //   this.disableLang = this.esitoPagamentoComponent.disableLang;
  //   console.log("disableLang " + this.disableLang);
  // }

  ngOnInit() {    
  } 


  get style(): ConfigurazioneStyle {
    return this.configStyleSrv.style;
  }

  get nomeImg(): string {
    return this.configStyleSrv?.logo;
  }

  get flagInt(): string {
    return this.configStyleSrv?.flagInt;
  }

  get nameDenominazioneEnte(): string {
    return this.configStyleSrv.denominazioneEnte;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  onChangeLanguage(langSelect: string){    
    // console.log("Sono in header " + langSelect);
    // this.translate.setDefaultLang(langSelect);
    this.translate.setDefaultLang(langSelect);
    this.translate.use(langSelect);
    //Passo la lingua ad autenticazioneComponent per settarla sul recaptcha
    this.eventEmitterService.onChangeLanguage(langSelect);
    this.configStyleSrv.setConfigurazioneInLingua(langSelect);
    this.storageSrv.setLingua(langSelect);
  } 

}
