import { Component, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormComponent } from 'src/app/_shared/form-component';
import { ConfigService } from '../../../../_shared/config.service';
import { ConfigurazioneStyleService } from '../../../../_shared/config/configurazione-style.service';

@Component({
  selector: 'app-check-regolamento',
  templateUrl: './check-regolamento.component.html',
  styleUrls: ['./check-regolamento.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class CheckRegolamentoComponent extends FormComponent implements OnInit {
  accettaRegolamentoServizio = false;
  accettaInformativaPrivacy = false;
  linkRegolamento: string;
  //linkInformativa: string;
  linkInformativa2: string;

  constructor(
    private configFile: ConfigService,
    private configStyleSrv: ConfigurazioneStyleService,
  ) {
    super();
  }

  ngOnInit(): void {
    //this.linkInformativa = `${this.configFile.getConfig().linkInformativa}`;
    this.linkRegolamento = `${this.configFile.getConfig().linkRegolamento}`;
    this.linkInformativa2 = `${this.configStyleSrv?.style?.linkPersonale}`;
  }

  ngAfterContentChecked(): void {
    if (this.isDetail) {
      this.disableControls();
    }
  }
}
