export class Persona {
  nome: string;
  cognome: string;
  codiceFiscale: string;
  indirizzo: string;
  civico: string;
  provincia: string;
  localita: string;
  cap: string;
  email: string;
  checkEstero:boolean;
  siglaProv: string;
  partitaIva: string;
}
