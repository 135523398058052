import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/_shared/authentication.service';
import { ConfigService } from 'src/app/_shared/config.service';
import { ConfigurazioneStyleService } from 'src/app/_shared/config/configurazione-style.service';
import { EventEmitterService } from 'src/app/_shared/event-emitter-service';
import { Utility } from 'src/app/_shared/utility';


@Component({
  selector: 'captcha-esito',
  templateUrl: './captchaEsito.component.html',
  styleUrls: ['./captchaEsito.component.css'],
})
export class CaptchaEsitoComponent implements OnInit {
  lingua: string;
  siteKey: string;
  token: string;
  codiceEnte: string;
  aFormGroup: FormGroup;

  constructor(private authsvr: AuthenticationService, private router: Router,
              private route: ActivatedRoute,
              private configStyle: ConfigurazioneStyleService,
              private translate :TranslateService,
              private formBuilder: FormBuilder,
              private configFile: ConfigService
              )
  {
      this.siteKey = this.configFile.getConfig().siteKey;
      this.lingua = this.configFile.getConfig().defaultLanguage;
  }


  ngOnInit(): void {

    // console.log("captcha component");
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });

    this.token = this.route.snapshot.paramMap.get('token');
    this.codiceEnte = this.route.snapshot.paramMap.get('codiceEnte');

    // console.log("token " + this.token);
    // console.log("this.codiceEnte " + this.codiceEnte);

    if (!Utility.isStringNullOrUndefinedOrEmpty(this.codiceEnte)) {
      this.configStyle.init(this.codiceEnte);
    }

    if (Utility.isStringNullOrUndefinedOrEmpty(this.token)) {
      this.router.navigate(['Errore']);
    }

     //internazionalizzazione
     if (this.route.snapshot.paramMap.get('lingua')) {
      this.lingua = this.route.snapshot.paramMap.get('lingua');
    }

    // this.anagrafica = new Anagrafica();
    this.translate.langs = [this.lingua];
    this.translate.setDefaultLang(this.lingua);
    this.translate.use(this.lingua);

  }

  handleSuccess(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.router.navigate(['EsitoPagamentoCaptcha'],  {queryParams: {token: this.token, lingua: this.lingua, codiceEnte: this.codiceEnte}});
  }

  get testDenominazioneEnte(): string {
    return this.configStyle.denominazioneEnte;
  }

  //Metodo registrato su eventemitter dell'header
  // onChangeLanguage(lang: string) {
  //    //this.lingua=lang;
  // }

}

