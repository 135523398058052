import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-download-links',
  templateUrl: './download-links.component.html',
  styleUrls: ['./download-links.component.css']
})
export class DownloadLinksComponent implements OnInit {
  @Input() ricevutaLink: string;
  @Input() fatturaLink: string;
  @Input() fatturaLinkVisible: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
