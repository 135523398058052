import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from './models/config';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private config: Config = {
    BaseUrlSito: '',
    BaseUrlAPI: '',
    BaseUrlSitoIC: '',
    BaseUrlAPIIC: '',
    BaseUrlSitoOS: '',
    BaseUrlAPIOS: '',
    tokenLeggiAPI: '',
    entiLeggiAPI: '',
    statoPagamentoAPI: '',
    pagaCarrelloAPI: '',
    leggiCarrelloAPI: '',
    urlRicevuta: '',
    urlFattura: '',
    linkInformativa: '',
    linkRegolamento: '',
    siteKey: '',
    defaultLanguage: '',
    langs: [''],
  };

  constructor(private httpClient: HttpClient) {}
  public init(): Promise<any> {
    const promise = this.httpClient
      .get<Config>(environment.configFile)
      .toPromise()
      .then((data: Config) => {
        this.config.BaseUrlAPI = data.BaseUrlAPI;
        this.config.BaseUrlSito = data.BaseUrlSito;
        this.config.BaseUrlAPI = data.BaseUrlAPI;
        this.config.tokenLeggiAPI = data.tokenLeggiAPI;
        this.config.entiLeggiAPI = data.entiLeggiAPI;
        this.config.statoPagamentoAPI = data.statoPagamentoAPI;
        this.config.pagaCarrelloAPI = data.pagaCarrelloAPI;
        this.config.leggiCarrelloAPI = data.leggiCarrelloAPI;
        this.config.urlRicevuta = data.urlRicevuta;
        this.config.urlFattura = data.urlFattura;
        this.config.linkInformativa = data.linkInformativa;
        this.config.linkRegolamento = data.linkRegolamento;
        this.config.siteKey = data.siteKey;
        this.config.defaultLanguage = data.defaultLanguage;
        this.config.langs = data.langs;
      });
    return promise;
  }

  public getConfig(): Config {
    return this.config;
  }
}
