import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthRequest } from './models/dtos/auth-request.js';
import { AuthResponse } from './models/dtos/auth-response.js';
import { EnteRequest } from './models/dtos/ente-request.js';
import { EnteResponse } from './models/dtos/ente-response.js';
import { PagamentoRequest } from './models/dtos/pagamento-request.js';
import { PagamentoResponse } from './models/dtos/pagamento-response.js';
import { CarrelloResponse } from './models/dtos/carrello-response.js';
// import { environment} from './../../environments/environment';
import { ConfigService } from './config.service';
import { StatoPagamentoRequest } from './models/dtos/statopagamento-request.js';
import { SipaEsito } from './models/dtos/sipa-response.js';

const httpOptions = {
  headers: new HttpHeaders({
    //progrIdCarrSorg: '55698'
  })
};

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  baseUrlApi: string;

  constructor(protected http: HttpClient, private configFile: ConfigService) {
    this.baseUrlApi = configFile.getConfig().BaseUrlAPI;
  }

  protected Post<TInput, TOutput>(data: TInput, suffixUrlApi?: string): Observable<TOutput> {
    // console.log(data);
    const url = !suffixUrlApi
      ? `${this.baseUrlApi}`
      : `${this.baseUrlApi}${suffixUrlApi}`;

    return this.http.post<TOutput>(url, data, httpOptions);
  }

  protected Get<TOutput>(urlAction: string, token: string): Observable<TOutput> {
    let url = `${this.baseUrlApi}${urlAction}`;
    //Non serve passare la lingua a leggi carrello 
    //url = `${url}/${token}/${lingua}`;
    url = `${url}/${token}`;
    return this.http.get<TOutput>(url);
  }

  public leggiToken(authRequest: AuthRequest): Observable<AuthResponse> {
    return this.Post<AuthRequest, AuthResponse>(authRequest, this.configFile.getConfig().tokenLeggiAPI);
  }

  public leggiEnti(enteRequest: EnteRequest): Observable<EnteResponse> {
    return this.Post<EnteRequest, EnteResponse>(enteRequest, this.configFile.getConfig().entiLeggiAPI);
  }

  public pagaCarrello(pagamentoRequest: PagamentoRequest): Observable<PagamentoResponse> {
    return this.Post<PagamentoRequest, PagamentoResponse>(pagamentoRequest, this.configFile.getConfig().pagaCarrelloAPI);
  }

  public leggiCarrello(token: string): Observable<CarrelloResponse>{
    return this.Get<CarrelloResponse>(this.configFile.getConfig().leggiCarrelloAPI, token);
  }

  public aggiornaStatoPagamento(statoPagamentoRequest:StatoPagamentoRequest): Observable<SipaEsito> {
    return this.Post<StatoPagamentoRequest, SipaEsito>(statoPagamentoRequest, this.configFile.getConfig().statoPagamentoAPI);
  }

}
