export class Utility{
  static isStringNullOrUndefinedOrEmpty(stringa: string): boolean{
    return stringa === null || stringa === undefined || stringa === '';
  }


  public arrotondamentoADueCifreDecimali(x) {
    return Number.parseFloat(x).toPrecision(2);
  }
}
