import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormComponent } from 'src/app/_shared/form-component';
import { Anagrafica } from 'src/app/_shared/models/anagrafica';
import { ControlContainer, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info-importo',
  templateUrl: './info-importo.component.html',
  styleUrls: ['./info-importo.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class InfoImportoComponent extends FormComponent implements OnInit {

@Input() anagrafica: Anagrafica;
@Input() seSipaPlus:any;
@Output() servizio = new EventEmitter<string>();
InputValue: any;
 test: string;


  get importoTotale(): number | string {
     return this.anagrafica.importoTotale;
  }

  constructor(private translate: TranslateService) {
    super();
   }

  ngOnInit(): void {
    if (!this.anagrafica.quantitaItem){ this.anagrafica.quantitaItem = 1 }
  }

  ngAfterContentChecked(): void {
    if (this.isDetail) {
      this.disableControls();
    }
  }

  checkSeparatore(nomeForm: string, val: string): void {
    const search = ',';
    const replaceWith = '.';
    //console.log("val is: " + val);
    const result = val.split(search).join(replaceWith);
    //console.log("val now: " + result);
    this.anagrafica.importo = +result;
}//

}
