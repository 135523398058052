import { Component, OnInit } from '@angular/core';
import { NgWizardConfig, THEME, StepChangedArgs, NgWizardService, StepValidationArgs } from 'ng-wizard';
import { Observable, of } from 'rxjs';
import { WizardDescrizione as WizardDescrizione } from './descrittore-titolo';
import { ActivatedRoute } from '@angular/router';
import { ConfigurazioneStyleService } from '../_shared/config/configurazione-style.service';
import { AuthStorageService } from '../_shared/auth-storage.service';

@Component({
  selector: 'app-pagamento-spontaneo',
  templateUrl: './pagamento-spontaneo.component.html',
  styleUrls: ['./pagamento-spontaneo.component.css']
})


export class PagamentoSpontaneoComponent implements OnInit{

  public tipoS:string;

  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    anchorSettings: {
      anchorClickable: false
    },
    toolbarSettings: {
      showNextButton: false,
      showPreviousButton: false
    }
  };


  idCarrelloSorgente: any;
  listIdPagamentoTas: any;
  statoPagamento: any;
  index: number;

  constructor(private ngWizardService: NgWizardService,
              private route: ActivatedRoute,
              private configStyleSrv: ConfigurazioneStyleService,
              private storageSrv: AuthStorageService) {


    const idTab: string = this.route.snapshot.paramMap.get('idtab');
    if (idTab){
      this.handleTab(idTab);
    }
  }


  tabs: WizardDescrizione[] = [
    { idtab: 'Pagamento', descrizione: 'Pagante'},
    { idtab: 'Pagamento', descrizione: 'Riepilogo dati ' },
    { idtab: 'Esito', descrizione: 'Esito transazione' }];

    //Label tabs applicando translate

  /*
  tabs: WizardDescrizione[] = [
    { idtab: this.translate.instant("TABS.PAGAMENTO"), descrizione: this.translate.instant("TABS.PAGANTE") },
    { idtab: this.translate.instant("TABS.PAGAMENTO"), descrizione: this.translate.instant("TABS.RIEPILOGO_DATI") },
    { idtab: this.translate.instant("TABS.ESITO"), descrizione: this.translate.instant("TABS.ESITO_TRANSAZIONE") }];
  */
  ngOnInit(): void {
    //console.log("pagamento spontaneo");
  }


  showPreviousStep(event?: Event): void {
    this.ngWizardService.previous();
  }

  showNextStep(event?: Event): void {
    this.ngWizardService.next();
  }

  resetWizard(event?: Event): void {
    this.ngWizardService.reset();
  }

  setTheme(theme: THEME): void {
    this.ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs): void {
    this.index = args.step.index;
    // console.log(args.step);
  }

  isValidFunctionReturnsBoolean(args: StepValidationArgs): boolean {
    return true;
  }

  isValidFunctionReturnsObservable(args: StepValidationArgs): Observable<boolean> {
    return of(true);
  }

  handleTab(idTab: string): void {
    if (this.tabs[2].idtab === idTab){
      // http://localhost:4200/Pagamento/Esito?idCarrelloSorgente=SIPA_CCIAA_RM_7202&listIdPagamentoTas=000000000065944&statoPagamento=CO&hashParity=lIcKBfajnFfjEJQSeEgqIgR5crJahTvDC%2BjBngRPd0PjtLB9JiBfVZRgpPdSAJiP7iegipbBQ7kUJHk4P1qIxoqW3SfyHtEjriQmnpb9eN0%3D
      this.config.selected = 2;
      this.idCarrelloSorgente = this.route.snapshot.queryParams.idCarrelloSorgente;
      this.listIdPagamentoTas = this.route.snapshot.queryParams.listIdPagamentoTas;
      this.statoPagamento = this.route.snapshot.queryParams.statoPagamento;

      //in questa fase lo storageSrv potrebbe essere vuoto
      let codiceEnte = this.storageSrv.getCodiceEnte();

      if (!codiceEnte){
        codiceEnte = this.idCarrelloSorgente.substring(5,13);
      }
      this.configStyleSrv.init(codiceEnte);
    }
  }

  onServizioDaAna(event){
    this.tipoS = event;
  }

}
