export class AuthRequest{
  sipaGuid: string;
  sipaCodiceEnte: string;
  sipaToken: string;
  lingua: string;

  protected constructor(sipaGuid?: string, sipaCodiceEnte?: string, lingua?: string){
    this.sipaGuid = sipaGuid;
    this.sipaCodiceEnte = sipaCodiceEnte;
    this.lingua = lingua;
  }

  static Create(guid: string, codiceEnte: string, lingua: string): AuthRequest{
    const output = new AuthRequest(guid, codiceEnte, lingua);
    return output;
  }
}
