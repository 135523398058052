import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurazioneStyle } from '../_shared/config/configurazione-style';
import { ConfigurazioneStyleService } from '../_shared/config/configurazione-style.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  // template: "<div></div>",
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, AfterViewInit {

  constructor(private configStyleSrv: ConfigurazioneStyleService,
              public translate: TranslateService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    //this.translate.resetLang(this.translate.currentLang);
    if (!this.translate.currentLang) {
      this.translate.reloadLang(this.translate.currentLang);
    }
  }

  get style(): ConfigurazioneStyle {
    return this.configStyleSrv.style;
  }

  get nameDenominazioneFooter(): string {
    return this.configStyleSrv.denominazioneFooter;
  }
}
