import { AuthRequest } from './auth-request';

export class EnteRequest extends AuthRequest{
  /**
   *
   */
  protected constructor(codiceEnte?: string) {
    super(null, codiceEnte);
  }

  static Create(codiceEnte: string, token?: string, guid?: string, lingua?: string): AuthRequest{
    const output = new EnteRequest(codiceEnte);
    output.sipaToken = token;
    output.sipaGuid = guid;
    output.lingua = lingua;
    return output;
  }
}
