import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AuthenticationService } from './../_shared/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthStorageService } from '../_shared/auth-storage.service';
import { ConfigurazioneStyleService } from '../_shared/config/configurazione-style.service';
import { Utility } from '../_shared/utility';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventEmitterService } from '../_shared/event-emitter-service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ConfigService } from '../_shared/config.service';
import { SipaPlusStorageService } from '../_shared/sipaplus-storage.service';

@Component({
  selector: 'app-autenticazione',
  templateUrl: './autenticazione.component.html',
  styleUrls: ['./autenticazione.component.css'],
})
export class AutenticazioneComponent implements OnInit, OnDestroy {
  // KEY_CODICE_ENTE: 'codiceEnte';
  lingua: string;
  siteKey: string;
  id: number;

  aFormGroup: FormGroup;

  private unSubscribe$ = new Subject();

  constructor(
    private configStyleSrv: ConfigurazioneStyleService,
    private authsvr: AuthenticationService,
    private router: Router,
    private authStorage: AuthStorageService,
    private route: ActivatedRoute,
    private configStyle: ConfigurazioneStyleService,
    private translate: TranslateService,
    private eventMitterLang: EventEmitterService,
    private formBuilder: FormBuilder,
    private configFile: ConfigService,
    private sipaPlusStorage: SipaPlusStorageService
  ) {//"6Lf5RzcaAAAAAMF0Bn0yUoneO4XOOnww3YJlf1oL";//
    this.siteKey = "6Lf5RzcaAAAAAMF0Bn0yUoneO4XOOnww3YJlf1oL";//this.configFile.getConfig().siteKey;
    this.lingua = this.configFile.getConfig().defaultLanguage;
    this.authStorage.clearAll();
    this.sipaPlusStorage.clearAll();
    this.authStorage.setEstero('');
    this.id = 0;
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  ngOnInit(): void {
    this.id = this.id + 1;
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required],
    });

    this.route.queryParams
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((params) => {
        const codiceEnte = params.codiceEnte;

        if (!Utility.isStringNullOrUndefinedOrEmpty(codiceEnte)) {
          this.authStorage.setCodiceEnte(codiceEnte);
          this.configStyle.init(codiceEnte);
        } else {
          this.router.navigate(['Errore']);
        }

        if (this.configStyle.flagInt == 'true') {
          //Se la lingua viene inserita come parametro la setto
          if (params.lingua) this.lingua = params.lingua;
          this.translate.setDefaultLang(this.lingua);
          this.translate.use(this.lingua);
          this.configStyle.setConfigurazioneInLingua(this.lingua);
        }
        this.authStorage.setLingua(this.lingua);
      });

    //eventemitter registrato con header per cambio lingua del recaptcha
    if (this.eventMitterLang.subsVar == undefined) {
      this.eventMitterLang.subsVar =
        this.eventMitterLang.invokeAutenticazioneComponentFunction.subscribe(
          (lang: string) => {
            this.onChangeLanguage(lang);
          }
        );
    }
  }

  handleSuccess(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.authsvr
      .login(this.lingua)
      .pipe(
        tap((isOk) => {
          if (isOk) this.router.navigate(['Pagamento']);
        })
        //takeUntil(this.unSubscribe$)
      )
      .subscribe();
  }

  //Metodo registrato su eventemitter dell'header
  onChangeLanguage(lang: string) {
    this.lingua = lang;
  }

  //Test escape
  test() {
    let variabile = '!£$%&/()=?^+ùàè*§°é*';

    console.log(escape(variabile));
  }

  get testDenominazioneEnte(): string {
    return this.configStyleSrv.denominazioneEnte;
  }
}
