import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  title = 'SIPA';
  @BlockUI() blockUI: NgBlockUI;

  constructor() {
  }

  ngOnInit(): void {
    // // ABA inizio
    // this.blockUI.start('Attendere, prego...');
    // setTimeout(() => {
    //   this.blockUI.stop();
    // }, 2000);
    // // ABA fine
  }

  // ABA inizio
  toggleBlocking(message?: string) {
    this.blockUI.start(message);

    setTimeout(() => {
      this.blockUI.stop();
    }, 2000);
  }
  // ABA fine
}
