import { Injectable } from '@angular/core';
import { AuthStorageService } from './auth-storage.service';
import { Utility } from './utility';
import { Guid } from './guid';
import { AuthRequest } from './models/dtos/auth-request';
import { Observable, Observer } from 'rxjs';
import { HttpService } from './http.service';
import { EntiService } from './services/enti.service';
import { SipaPlusStorageService } from './sipaplus-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  get isLoggato(): boolean {
    return !Utility.isStringNullOrUndefinedOrEmpty(this.authStorage.getToken());
  }

  constructor(
    private httpApi: HttpService,
    private authStorage: AuthStorageService,
    private entiSvr: EntiService,
    private sipaPlusStorage: SipaPlusStorageService) {
  }

  login(lingua: string): Observable<boolean> {
    const guid = Guid.newGuid();
    // console.log("Guid da login " + guid );
    const codiceEnte = this.authStorage.getCodiceEnte();
    return new Observable((observer: Observer<boolean>) => {
      this.httpApi.leggiToken(
         AuthRequest.Create(guid, codiceEnte, lingua))
      .subscribe(
        (responseBody) => {
          const esito = responseBody.sipaEsito.codiceEsito;
          if (esito === 'OK') {
            this.authStorage.setGuid(guid);
            this.authStorage.setToken(responseBody.sipaToken);
            this.entiSvr.loadEnti(codiceEnte, lingua).subscribe();
            observer.next(true);
          }else{
            this.logout();
            alert(responseBody.sipaEsito?.descrizioneEsito ?? 'Operazione fallita');
            observer.next(false);
          }
        }, (error) => {
          this.logout();
          console.error(error);
        }, () => {
          observer.complete();
        });
    });
  }

  logout(): void{
    this.authStorage.setGuid(null);
    this.authStorage.setToken(null);
    this.authStorage.setEstero('');
    this.authStorage.setCodiceEnte(null);
    this.authStorage.setLingua(null);
    // this.authStorage.setVisibility(null);
    this.authStorage.clearAll();
    this.sipaPlusStorage.clearAll();
  }
}
