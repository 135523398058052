import { Component, OnInit, Input, AfterContentChecked, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Persona } from 'src/app/_shared/models/Persona';
import { NgForm, ControlContainer } from '@angular/forms';
import { FormComponent } from 'src/app/_shared/form-component';
import ProvinceData from '../../../../../assets/province.json';
import { Province } from 'src/app/_shared/models/province.js';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthStorageService } from 'src/app/_shared/auth-storage.service';
import { Utility } from 'src/app/_shared/utility';


@Component({
  selector: 'app-anagrafica-persona',
  templateUrl: './anagrafica-persona.component.html',
  styleUrls: ['./anagrafica-persona.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class AnagraficaPersonaComponent extends FormComponent implements OnInit, AfterContentChecked {
  @BlockUI() blockUI: NgBlockUI;
  @Input() persona: Persona = new Persona();
  @Input() suffixTitle: string; // del pagante
  @Output() CheckEsteroEmit = new EventEmitter<boolean>();
  provinceEnabled: Province[];
  checkEstero = false;
  provinciaEstero: Province = null;
  

  constructor(private cd: ChangeDetectorRef, private storage: AuthStorageService,) {
    super();
  }

  faPlus = faPlus;
  faMinus = faMinus;
  isOpen = true;
  provinciaEstera = false;

  ngOnInit(): void {
    if(Utility.isStringNullOrUndefinedOrEmpty(this.storage.getEstero()))
    {
      this.provinceEnabled = ProvinceData as Province[];
      this.checkEstero=false;
    }
    else
    {
      this.provinceEnabled = [{ "nome": "Estero", "codice": "EE" }] as Province[];
      if(this.form.form.get('cliente')!=undefined)
      {
        this.form.form.get('cliente').get("provincia").setValue("EE");
      }
      this.checkEstero=true;
    }
    var attendere = '';
    this.blockUI.start(attendere);

    setTimeout(() => {
      this.blockUI.stop();
    }, 2000);
  }

  ngAfterContentChecked(): void {
    if (this.isDetail) {
      this.disableControls();
    }
  }
  clickAccordion(event): void {
    this.isOpen = event.nextState;
  }

  clickEstero(nomeForm: string): void {
    if (this.checkEstero) {
      this.checkEstero = false;
      this.provinceEnabled = ProvinceData as Province[];
      this.form.form.get(nomeForm).get("provincia").setValue(null)
    }
    else {
      this.checkEstero = true;
      this.provinceEnabled = [{ "nome": "Estero", "codice": "EE" }] as Province[];
      this.form.form.get(nomeForm).get("provincia").setValue("EE");
    }

    if(nomeForm=='pagante')
    {
      if(!this.checkEstero)
        this.storage.setEstero('');
      else
      {
        this.storage.setEstero(nomeForm);
      }
    }

    this.CheckEsteroEmit.emit(this.checkEstero);
    this.cd.detectChanges();
  }//END clickEstero

  changeProvincia(nomeForm: string, val:string): void {
          let scelta: string;
          this.provinceEnabled.forEach(function(elem){
            if(elem.codice === val){
              scelta= elem.nome;
            }
          });
          this.persona.siglaProv= scelta;
  }
}
