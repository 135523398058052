<form class="mt-4 mb-4" #anagraficaForm="ngForm">
  <!-- BOX   (sePlus)="metodoSePlus($event)" (controlloCinqueServizi)="piuDiCinqueSel($event)"  (servizio)="takeService($event)"-->

  <app-info-pagamento [form]="anagraficaForm" [anagrafica]="anagrafica" [nameModelGroup]="'pagamento'">
    <!-- (servizio)="onServizio($event)">-->
  </app-info-pagamento>

    <app-info-pagamento-plus [form]="anagraficaForm" [anagrafica]="anagrafica" [nameModelGroup]="'pagamento'" 
		(servizio)="takeService($event)" (inviaSePlus)="metodoSePlus($event)" 
		(controlloCinqueServizi)="piuDiCinqueSel($event)" [nascondiRighe]="clearTabella"
        (nascondiRigheReturn)="leggeReturnRighe($event)" 
		(controlloSeVuotoPagamentoSpontaneoEE)="seVuotoPagamentoSpontaneo($event)" 
		(controlloSeVuotoImportoEE)="seVuotoTotale($event)"
    (controlloAliquotaMaggioreZero)="aliquotaMaggioreZero($event)"> <!--A.A. se i campi aliquotaIva sono maggiori di 0 apro anagrafica-persona-->
    </app-info-pagamento-plus>
    <!--
    <app-dettagli [form]="anagraficaForm" [anagrafica]="anagrafica" [nameModelGroup]="'pagamento'" (servizio)="takeService($event)" (inviaSePlus)="metodoSePlus($event)" (controlloCinqueServizi)="piuDiCinqueSel($event)">
    </app-dettagli>-->

  <app-info-importo [seSipaPlus]="sePlus" *ngIf="visualizzaImportoSeSipaBase" [form]="anagraficaForm"
    [anagrafica]="anagrafica" [nameModelGroup]="'importo'"></app-info-importo>

  <app-anagrafica-persona [form]="anagraficaForm" [persona]="anagrafica.pagante" [nameModelGroup]="'pagante'"
    suffixTitle="{{ 'ANAGRAFICA.DEL_PAGANTE' | translate}}" (CheckEsteroEmit)="EventCheckEstero($event)">
  </app-anagrafica-persona>
  <!--[disabled]="{{campiObbligatori}}" oppure [disabled]="campiObbligatori"-->
  <div class="row mt-4 mb-4">
    <div class="col-sm-12 text-center">
      <div class="chechboxInline">  
        <input type="checkbox" class="checkBoxClass" id="checkPagante" [(ngModel)]="paganteNonCoincide"
          (click)="checkPaganteClick()" [ngModelOptions]="{standalone: true}" [disabled]="isDisabled">
        <label class="labelCheckbox" for="checkPagante">{{ 'ANAGRAFICA.DATI_FATTURAZIONE' | translate}}</label>
      </div>
      <div type="button" class="btn btn-success ml-2" (click)="copiaValoriClientePagante()" [hidden]="!isDisabled" ngbTooltip="{{ 'MODALE.TOOLTIP' | translate}}">{{ 'BUTTON.COPIA' | translate}}</div>
    </div>
  </div>

  <!-- ABA Attenzione visibility-->
  <app-anagrafica-persona *ngIf="paganteNonCoincide" [form]="anagraficaForm" [persona]="anagrafica.cliente"
    [nameModelGroup]="'cliente'" suffixTitle="{{ 'ANAGRAFICA.DEL_CLIENTE' | translate}}">
  </app-anagrafica-persona>

  <!-- app-anagrafica-persona *ngIf="paganteNonCoincide && this.visibility" [form]="anagraficaForm"
    [persona]="anagrafica.cliente" [nameModelGroup]="'cliente'" suffixTitle="{{ 'ANAGRAFICA.DEL_CLIENTE' | translate}}">
  </app-anagrafica-persona -->

  <!-- ABA Attenzione controllare FORM SBAGLIATO-->
  <app-info-codice-destinatario [form]="anagraficaForm" [anagrafica]="anagrafica" [nameModelGroup]="'pagamento'"
    [isDetail]="false" [datiObbligatori] = "campiObbligatori"></app-info-codice-destinatario>

  <!-- app-info-codice-destinatario [form]="pagamentoForm" [anagrafica]="anagrafica"
  [nameModelGroup]="'pagamento'" [isDetail]="false" *ngIf="this.visibility"></app-info-codice-destinatario -->


  <app-check-regolamento [form]="anagraficaForm" [nameModelGroup]="'codiceDestinatario'"></app-check-regolamento>

  <hr>
  <div class="form-group col-sm-12 mt-4">
    <div class="text-center">
      <button type="button" class="btn btn-info" (click)="clearForm(anagraficaForm)">{{ 'BUTTON.RESET' |
        translate}}</button>
      <button type="button" class="btn btn-success ml-2" (click)="onSubmit(anagraficaForm)">{{ 'BUTTON.AVANTI' |
        translate}}</button>
    </div>
  </div>
</form>
<app-modal-check-error></app-modal-check-error>
