import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {
  private readonly KEY_TOKEN: string = 'sipaToken';
  private readonly KEY_GUID: string = 'sipaGuid';
  private readonly KEY_ENTE: string = 'codiceEnte';
  private readonly KEY_LINGUA: string = 'lingua';
  // private readonly KEY_VISIBILITY: string = 'visibility';
  private readonly KEY_ESTERO: string = 'estero';

  get storage(): Storage { return sessionStorage; }

  constructor() {

  }

  getToken(): string {
    return this.storage.getItem(this.KEY_TOKEN);
  }

  setToken(token: string): void {
    this.storage.setItem(this.KEY_TOKEN, token);
  }

  setEstero(estero: string)
  {
    this.storage.setItem(this.KEY_ESTERO, estero);
  }

  getEstero(): string {
    return this.storage.getItem(this.KEY_ESTERO);
  }

  getGuid(): string {
    return this.storage.getItem(this.KEY_GUID);
  }

  setGuid(token: string): void {
    this.storage.setItem(this.KEY_GUID, token);
  }

  getCodiceEnte(): string {
    return this.storage.getItem(this.KEY_ENTE);
  }

  setCodiceEnte(codiceEnte: string): void {
    this.storage.setItem(this.KEY_ENTE, codiceEnte);
  }

  getLingua(): string {
    return this.storage.getItem(this.KEY_LINGUA);
  }

  setLingua(lingua: string): void {
    this.storage.setItem(this.KEY_LINGUA, lingua);
  }

  // setVisibility(vis: string): void
  // {
  //   this.storage.setItem(this.KEY_VISIBILITY, vis);
  // }

  // getVisibility(): string {
  //   return this.storage.getItem(this.KEY_VISIBILITY);
  // }

  clearAll()
  {
    this.storage.clear();
  }

  clear()
  {
    this.setGuid(null);
    this.setToken(null);
    //this.setEstero(null);
    this.setCodiceEnte(null);
    this.setLingua(null);
    // this.setVisibility(null);
  }
}
