import { Component, Input, OnInit } from '@angular/core';
import { ItemCarrello } from 'src/app/_shared/models/dtos/pagamento-request';
import { CausaleDto } from 'src/app/_shared/models/ente';

@Component({
  selector: 'app-header-esito-plus',
  templateUrl: './header-esito-plus.component.html',
  styleUrls: ['./header-esito-plus.component.css']
})
export class HeaderEsitoPlusComponent implements OnInit {
  constructor() { }
  @Input() listaCausaliMostrate: Array<CausaleDto> = new Array<CausaleDto>();

  importoTotale: string;
  ngOnInit() {
    let tmpimportoTotale: number = 0;
    this.listaCausaliMostrate.forEach((item) => {
      let temp: number = +item.importo;
      tmpimportoTotale = tmpimportoTotale + temp;
    });
    this.importoTotale = tmpimportoTotale.toFixed(2);
  }
}
