<h2 class="text-center mb-3 mt-3">{{ 'PAGANTE.TITLE' | translate }}</h2>
<!-- <div *ngIf="this.esitoVisible === true">
  <div class="card mb-4" *ngIf="this.isOK === true">
    <div class="card-body">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <h4>{{ 'ESITO.PAGAMENTO_OK' | translate}}</h4>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <Label class="label">{{ 'ESITO.ID_PAGAMENTO' | translate}}:</Label>
          <Label>{{this.listIdPagamentoTas}}</Label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <Label class="label">{{ 'ESITO.TITLE' | translate}}:</Label>
          <Label>{{this.descrizioneEsito | translate}}</Label>
        </div>
      </div>
      <div class="form-row" *ngIf="!this.isEmail">
        <div class="form-group col-sm-12">
          <Label>
            {{ 'ESITO.MAIL' | translate}}
          </Label>
        </div>
      </div>
    </div>
  </div>
</div> -->
<ng-wizard [config]="config">
  <ng-wizard-step title="{{ 'TABS.PAGAMENTO' | translate}}" description="{{ 'TABS.PAGANTE' | translate}}">
  </ng-wizard-step>

  <!-- <ng-wizard-step [title]="tabs[1].idtab" [description]="tabs[1].descrizione">
  </ng-wizard-step> -->

  <ng-wizard-step title="{{ 'TABS.PAGAMENTO' | translate}}" description="{{ 'TABS.RIEPILOGO_DATI' | translate}}">
  </ng-wizard-step>

  <ng-wizard-step title="{{ 'TABS.ESITO' | translate}}" description="{{ 'TABS.ESITO_TRANSAZIONE' | translate}}">
    <form class="mt-4 mb-4" #esitoForm="ngForm" *ngIf="this.anagrafica">

      <!-- <app-esito [idCarrelloSorgente]="idCarrello" [listIdPagamentoTas]="idPagamento" [statoPagamento]="stato"
            [isEmail]="true">
            </app-esito> -->

      <app-header-esito [form]="esitoForm" [nameModelGroup]="'info-pagamento'" [isDetail]="true"
        [applServizioDescrizione]="anagrafica.applicazioneDesc" [causale]="anagrafica.causaleRaggruppamento"
        [enteDescrizione]="anagrafica.enteDesc" [lingua]="lingua">
      </app-header-esito>

      <app-header-esito-plus [listaCausaliMostrate]="listaCausali">
      </app-header-esito-plus>

      <!-- <app-info-importo *ngIf="mostraTabellaPlus" [form]="esitoForm" [anagrafica]="anagrafica" [nameModelGroup]="'info-importo'" [isDetail]="true">
            </app-info-importo> -->

      <app-anagrafica-persona class="mb-4" [form]="esitoForm" [persona]="anagrafica.pagante"
        [nameModelGroup]="'pagante'" suffixTitle="{{ 'ANAGRAFICA.DEL_PAGANTE' | translate}}" [isDetail]="true">
      </app-anagrafica-persona>

      <app-info-codice-destinatario [form]="esitoForm" [anagrafica]="anagrafica" [nameModelGroup]="'pagamento'"
        [isDetail]="true"></app-info-codice-destinatario>

      <app-anagrafica-persona *ngIf="paganteNonCoincide" [form]="esitoForm" [persona]="anagrafica.cliente"
        [nameModelGroup]="'cliente'" suffixTitle="{{ 'ANAGRAFICA.DEL_CLIENTE' | translate}}" [isDetail]="true">
      </app-anagrafica-persona>

      <app-download-links [ricevutaLink]="urlRicevuta" [fatturaLink]="urlFattura"
        [fatturaLinkVisible]="linkFatturaPresente">
      </app-download-links>
    </form>
  </ng-wizard-step>
</ng-wizard>
